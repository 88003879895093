import * as API from "../../constant/api";

export async function handleLogin(data) {
  let response;
  let error;

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const body = JSON.stringify({
    email: data.email,
    password: data.password,
    role: "orgAdmin",
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.LOG_IN, requestOptions);
    response = await res.json();
    if (response.status !== "fail") {
      localStorage.setItem("auth", JSON.stringify(response.token));
      localStorage.setItem("user", JSON.stringify(response.data.user));
    }
  } catch (err) {
    error = await err;
  }

  return { response, error };
}

export async function logOut() {
  let error;
  let response;

  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  try {
    const res = await fetch(API.LOG_OUT, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function uploadBook(book) {
  let error;
  let response;
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const body = JSON.stringify({ ...book });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.UPLOAD_BOOK, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function handleUpdatePassword(data) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  console.log(data, "payload");
  const body = JSON.stringify({
    currentPassword: data.current_password,
    password: data.new_password,
    passwordConfirm: data.confirm_password,
  });

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.UPDATE_PASSWORD, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function handleForgotPassword(email) {
  let error;
  let response;

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const body = JSON.stringify({
    role: "orgAdmin",
    email: email,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.FORGOT_PASSWORD, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function handleResetPassword(password, passwordConfirm, id) {
  let error;
  let response;

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const body = JSON.stringify({
    role: id.split("&")[1],
    password,
    passwordConfirm,
  });

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      `${API.RESET_PASSWORD}/${id.split("&")[0]}`,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getProfile() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.GET_PROFILE, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function wallet() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.WALLET, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function register(data) {
  let error;
  let response;

  function generatePid() {
    const date = new Date();
    return (
      date.getDay().toString() +
      date.getYear() +
      date.getMinutes().toString() +
      date.getMilliseconds().toString()
    );
  }

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", `application/json`);

  // full_name: "",
  //   email: "",
  //   phone: "",
  //   password: "",
  //   confirm_password: "",
  //   brand_name: "",
  //   registered_name: "",
  //   registered_address: "",
  //   domain: "",
  //   gst: "",
  //   website: "",

  const pID = generatePid();

  const body = JSON.stringify({
    role: "publisher",
    fullName: data.full_name,
    email: data.email,
    mobile: data.phone,
    password: data.password,
    passwordConfirm: data.confirm_password,
    registeredName: data.registered_name,
    registeredAddress: data.registered_address,
    brandName: data.brand_name,
    pID,
    gstIN: data.gst,
    domain: data.domain,
    website: data.website,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.SIGN_UP, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

////////////////////////////////////////

export async function getMyOrganisation() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.GET_MY_ORGANISATION, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function updateOrganisationProfile(data, tAndc) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const formdata = new FormData();
  formdata.append("photo", data.photo);
  formdata.append("email", data.email);
  formdata.append("fullName", data.fullName);
  formdata.append("mobile", data.mobile);
  if(tAndc) {
    formdata.append("tnCAccepted", tAndc);
  };

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.UPDATE_PERSONAL_PROFILE, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function deleteProfilePic() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.DELETE_PROFILE_PICTURE, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function deleteBusinessProfilePic() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.DELETE_BUSINESS_PROFILE, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function updateOrganistionPicture(picture) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const formdata = new FormData();
  formdata.append("companyPhoto", picture);

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.UPDATE_ORGANISATION_PICTURE, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}
export async function addAMember(data) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      // role: "publisher",
      ...data,
    }),
    redirect: "follow",
  };

  try {
    const res = await fetch(API.ADD_NEW_ORGANISATION, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getAllOrganisers() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.GET_ALL_ORGANISERS, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function makePrimary(id) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.MAKE_PRIMARY + `${id}`, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function deleteMember(id) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.DELETE_MEMBER + `${id}`, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getOrderHistory() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.GET_MY_BOOK_HISTORY, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function allOrganisationUser(status) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);
   
  
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(`${API.ALL_ORGANISATION_USER}${status == "status" ? "" : "?status="+status}`, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function addNewUser(data, extra) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const body = JSON.stringify({
    role: "orgUser",

    ...data,
    ...extra,
  });
  const requestOptions = {
    method: "POST",
    body,
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.ADD_NEW_ORGANISATION_USER, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getOrderHistoryofUser(id) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      API.GET_ORDER_HISTORY_OF_USER + `${id}`,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function allDepartements() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.GET_ALL_DEPARTMENTS, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function createDepartment(data, ides) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      departmentName: data,

      orgUsers: ides,
    }),
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.CREATE_DEPARTMENT, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function searchUser(text) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.SEARCH_USER + text, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}
export async function getADepartment(id) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.GET_A_DEPARTMENT + `${id}`, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function upadteDepartmenName(data, id) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({
      departmentName: `${data}`,
    }),
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.CREATE_DEPARTMENT + id, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getuserofDepartment(id) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(
      API.GET_USER_OF_DEPARTMENT + id + "/orgUsers",
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function addUserinDep(id, data) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const body = JSON.stringify({
    departmentId: id,
    users: data,
  });

  const requestOptions = {
    method: "POST",
    body,
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.ASSIGN_USER_TO_DEPARTMENT, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

// BOOKS

export async function getALLBOOK(id) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.GET_ALL_BOOK, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getbookdetail(id) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.GET_BOOK_DETAILS + id, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

/// SETS

export async function getAllSets(id) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.GET_All_SETS, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getsetdetail(id) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.GET_SET_DETAILS + id, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

/// cart post /////////////////

export async function addBooktoCart(id, validity) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      bookId: id,
      validity: validity,
    }),
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.ADD_BOOK_TO_CART, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}
export async function addUserstoCart(id) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      userId: id,
    }),
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.ADD_USERS_TO_CART, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}
export async function addDepartmentstoCart(id) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      departmentId: id,
    }),
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.ADD_DEPARTMENT_TO_CART, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function addSetstoCart(id, validity) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      setId: id,
      validity: validity,
    }),
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.ADD_SET_TO_CART, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

/// cart post /////////////////

/// cart get /////////////////

export async function getBooksforAssign() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.GET_ALL_BOOK_FOR_ASSIGN, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getBooksfromCart() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.GET_BOOKS_FROM_CART, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getUsersfromCart() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.GET_USERS_FROM_CART, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getDepartmentsfromCart() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.GET_DEPARTMENTS_FROM_CART, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getsetsforAssign() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.GET_ALL_SET_FOR_ASSIGN, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getSetsfromCart() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.GET_SETS_FROM_CART, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

/// cart get /////////////////

/// cart delete ///////////////

export async function deleteBooksfromCart(id, validity) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.DELETE_BOOK_FROM_CART + id, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function deleteUsersfromCart(id) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.DELETE_USERS_FROM_CART + id, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function deleteDepartmentsfromCart(id) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(
      API.DELETE_DEPARTMENT_FROM_CART + id,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function emptyCart() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.EMPTY_CART, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function deleteSetsfromCart(id, validity) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.DELETE_SET_FROM_CART + id, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

/// cart delete ///////////////

// add user using excel file //
export async function downloadExcelfile() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const res = await fetch(API.DOWNLOAD_EXCEL_FILE, requestOptions)
    .then((res) => res.blob())
    .then((blob) => {
      var file = window.URL.createObjectURL(blob);
      window.location.assign(file);
    });

  return { response, error };
}

export async function addUserInBulk(file) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  const formdata = new FormData();
  console.log(file, "hello");
  formdata.append("excelFile", file);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.ADD_USERE_IN_BULK, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }
  console.log(response, "hfjhfhjfhjfh");

  return { response, error };
}

//// cart final step/////
export async function genrateOrderIdforIndiviual() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API.GENERATE_ORDERID_FOR_INDIVIUAL, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function genrateOrderIdforDepartment() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(
      API.GENERATE_ORDERID_FOR_DEPARTMENT,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function otpVerify(otp) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      otp: `${otp}`,
    }),
    redirect: "follow",
  };
  try {
    const res = await fetch(API.VERIFY_OTP, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function genrateSetOrderIdforIndiviual() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(
      API.GENERATE_SET_ORDERID_FOR_INDIVIUAL,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function genrateSetOrderIdforDepartment() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(
      API.GENERATE_SET_ORDERID_FOR_DEPARTMENT,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function otpVerifyforSets(otp) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      otp: `${otp}`,
    }),
    redirect: "follow",
  };
  try {
    const res = await fetch(API.VERIFY_OTP_FOR_SET, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

//// cart final step/////

/// notification

export async function getallNotification() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.GET_ALL_NOTIFICATION, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function updateNotification(id) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(`${API.UPDATE_NOTIFICATION}${id}`, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}
// settings

export async function getallTickets(page, type, resolutionDate, ticketdate) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      `${API.GET_ALL_TICKET}?status=${type}&page=${page}&limit=5&sort=${resolutionDate}&sort=${ticketdate}`,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getAllComments(id) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      `${API.GET_ALL_COMMENTS}${id}/comment`,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function createNewTicket(data) {
  let error;
  let response;

  console.log(data, "payload");
  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);

  let formData = new FormData();

  formData.append("type", data.type);
  formData.append("subject", data.subject);
  formData.append("description", data.description);
  formData.append("toPortalId", data.toportalId);
  formData.append("toPortalName", data.toportalName);
  formData.append("file", data.file);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  try {
    const res = await fetch(API.CREATE_NEW_TICKET, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function createNewcomments(comments, id) {
  let error;
  let response;

  console.log(comments, id, "payload");
  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const row = JSON.stringify({
    comment: comments,
  });
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: row,
    redirect: "follow",
  };

  try {
    const res = await fetch(`${API.Add_COMMENTS}${id}/comment`, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function updateStatus(id, status) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      `${API.UPDATE_STATUS}${id}?status=${status}`,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function searchPortalId(text) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(`${API.SEARCH_FOR_TICKET}${text}`, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}
// search book

export async function searchBookbyTitle(text) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOption = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      `${API.SEARCH_BOOK_BY_TITLE}${text}`,
      requestOption
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function searchBookbyBookId(text) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOption = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      `${API.SEARCH_BOOK_BY_BOOKID}${text}`,
      requestOption
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}
