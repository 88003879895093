import { useState, useEffect, useContext } from "react";
import {
  Page,
  Navbar,
  DialogBox,
  BtnSmallWhite,
  BtnSmall,
  PopUp,
  BtnSmallDisabled,
} from "../components";

import UserPic from "../assets/images/userPic.png";
import Member from "../assets/images/member.png";
import {
  getMyOrganisation,
  deleteBusinessProfilePic,
  updateOrganistionPicture,
  addAMember,
  makePrimary,
  deleteMember,
  getAllOrganisers,
} from "../assets/Js/script";
import { useNavigate } from "react-router-dom";
import HeaderIcon from "../components/Header_icon";
import { AuthContext } from "../context/auth";
import Loader from "../components/loader";
import { useForm } from "react-hook-form";
import { RESET_PASSWORD } from "../constant/api";
import { ToastContainer, toast } from "react-toastify";

export default function BusinessProfile() {
  const notify = (message) => toast(message);

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const [miniLoader, setMiniLoader] = useState(false);
  const [teaMember, setTeaMember] = useState(null);
  const [loading, setLoading] = useState(true);
  const [memberSelected, setMemberSelected] = useState(null);
  const [newMember, setNewMember] = useState({
    name: null,
    email: null,
    tel: null,
    imgSrc: null,
    primary: false,
  });
  const [modals, setModals] = useState({
    newMember: false,
    deleteMember: false,
    makePrimary: false,
    primary: false,
    memberAdded: false,
    showPage: false,
    btnColor1: true,
    btnColor2: false,
    deleteImage: false,
  });

  const { user, setUser } = useContext(AuthContext);
  const [isProfileDetailsSelected, setIsProfileDetailsSelected] =
    useState(true);
  const [myOrganisation, setMyOrganisation] = useState({ local: false });
  const [myProfile, setMyProfile] = useState(null);

  //-- form add member

  const navigate = useNavigate();
  const addNewMemberDisabled =
    !newMember.name || !newMember.email || !newMember.tel;

  //// api`s

  async function handleNewMember(data) {
    // setLoading(true)
    setNewMember((prev) => ({
      ...prev,
      name: data?.name,
      email: data?.email,
      tel: data?.tel,
    }));
    if (isValid && data) {
      setMiniLoader(true);
      const { response } = await addAMember({
        fullName: data.name,
        email: data.email,
        mobile: data.tel,
        // imgSrc: newMember.imgSrc,
        // primary: false,
      });
      if (response.status === "success") {
        setTeaMember((prevState) => [
          ...prevState,
          {
            fullName: data.name,
            email: data.email,
            mobile: data.tel,
            imgSrc: newMember.imgSrc,
            primary: false,
          },
        ]);
        setLoading(false);
        setMiniLoader(false);
        setModals((prevState) => ({
          ...prevState,
          memberAdded: true,
          newMember: false,
        }));
        reset();
        window.location.reload();
      } else {
        setMiniLoader(false);
        notify(response?.message ? response?.message : "Something went wrong");
      }
    }
  }

  async function handleMakePrimary(id, username) {
    setLoading(true);
    const { response } = await makePrimary(id);
    if (response.status === "success") {
      setLoading(false);

      setModals((prev) => ({ ...prev, makePrimary: true, username: username }));
    } else {
      setLoading(false);

      notify(response?.message ? response?.message : "Something went wrong");

    }
  }

  async function handleMemberDelete(id, memberPcp, status) {
    if (user?.isPCP && (status === "active" || status === "pending")) {
      if (!memberPcp) {
        setLoading(true);
        const { response } = await deleteMember(id);
        setLoading(false);
        setModals((prevState) => ({ ...prevState, deleteMember: true }));
        
        // setTeaMember((prevState) => [
        //   ...prevState.filter((item) => id !== item.id),
        // ]);
      }
    }
    // setTeaMember((prevState) => prevState.filter((member) => member.id !== id));
  }


  async function deletePicture() {
    setModals((prev) => ({ ...prev, deleteImage: true }));
    const { response } = await deleteBusinessProfilePic(
      myOrganisation.organisationPhoto
    );

    if (response.status === "success") {
      setMyOrganisation((prev) => ({ ...prev, organisationPhoto: null }));
      setModals((prev) => ({ ...prev, deleteImage: false }));
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
  }

  useEffect(() => {
    const organisation = async () => {
      const { response } = await getMyOrganisation();
      if (response.status === "success") {
        setMyOrganisation({ ...response.data.organisation });
        setLoading(false);
      }
    };

    const teaMembers = async () => {
      const { response } = await getAllOrganisers();

      if (response.status === "success") {
        setTeaMember([...response.data.admins]);
        setLoading(false);
      }
    };
    teaMembers();

    organisation();
  }, []);

  ///---------
  useEffect(() => {
    window.addEventListener("click", handleClosePop);

    function handleClosePop() {
      setModals((prevState) => ({ ...prevState, primary: false }));
    }

    return () => {
      window.removeEventListener("click", handleClosePop);
    };
  }, []);

  function notification() {
    setModals((prevState) => ({ ...prevState, memberAdded: true }));
  }

  useEffect(() => {
    window.addEventListener("click", handleClosePop);
    function handleClosePop() {
      setMemberSelected(null);
    }
    return () => {
      window.removeEventListener("click", handleClosePop);
    };
  }, []);

  return (
    <Page active="profile">
      <Navbar />
      {loading ? (
        <Loader show={loading} />
      ) : (
        <>
          <h1 className=" hidden lg:block lg:text-h1 text-h3 text-tertiary-dark lg:text-heading text-left font-semibold ">
            <button className="mr-3 lg:hidden" onClick={() => navigate(-1)}>
              <svg
                width="12"
                height="20"
                viewBox="0 0 12 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.0491 2.5473L9.4174 0.924805L0.351562 9.9998L9.42656 19.0748L11.0491 17.4523L3.59656 9.9998L11.0491 2.5473Z"
                  fill="#702963"
                />
              </svg>
            </button>
            Business Profile
          </h1>
          <HeaderIcon heading={"Business Profile"} />
          <div className="flex flex-col lg:flex-row mb-20 lg:mb-0">
            <div>
              <div className="lg:mb-4 flex flex-col lg:flex-row justify-between w-full">
                <div className="flex flex-col justify-evenly items-center bg-[#fff] lg:bg-primary-extra-light border-primary-dark-20 lg:border-1 border-solid lg:w-263 h-205 lg:mr-4">
                  <p className="text-p1 text-secondary-light">
                    Organisation Name
                  </p>

                  <div
                    className={`bg-[#bb7aaf] flex justify-center items-center w-24 h-24 rounded-full relative`}
                  >
                    {myOrganisation?.organisationPhoto === null ? (
                      <span
                        className={`text-[50px] uppercase font-bold text-[#fff]`}
                      >
                        {myOrganisation?.brandName[0]}
                      </span>
                    ) : (
                      <img
                        className="block w-24 h-24 rounded-full object-cover"
                        src={
                          myOrganisation.local
                            ? URL.createObjectURL(
                                myOrganisation.organisationPhoto
                              )
                            : `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/portals/${myOrganisation.organisationPhoto}`
                        }
                        alt="user's"
                      />
                    )}
                    <label
                      htmlFor="photo"
                      className="hidden w-[34px] h-[34px] rounded-full bg-[#ebe8e8e8] lg:flex items-center justify-center absolute right-0 -top-2"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.5 5.25L18.75 1.5L3.75 16.5L2.25 21.75L7.5 20.25L22.5 5.25ZM15.75 4.5L19.5 8.25L15.75 4.5ZM3.75 16.5L7.5 20.25L3.75 16.5Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </label>
                    <input
                      onChange={async ({ target: { files } }) => {
                        setMyOrganisation((prevState) => ({
                          ...prevState,
                          organisationPhoto: files[0],
                          local: true,
                        }));
                        // setLoading(true);
                        const { response } = await updateOrganistionPicture(
                          files[0]
                        );
                        if (response.status === "success") {
                          setLoading(false);
                        } else {
                          notify(
                            response?.message
                              ? response?.message
                              : "Something went wrong"
                          );
                        }
                      }}
                      name="photo"
                      id="photo"
                      type="file"
                      className="hidden"
                    />

                    <button
                      onClick={() =>
                        setModals((prev) => ({ ...prev, deleteImage: true }))
                      }
                      className="hidden w-[34px] h-[34px] rounded-full bg-[#ebe8e8e8] lg:flex items-center justify-center absolute -right-4 top-7"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.8 4.7998C13.8 4.32241 13.6104 3.86458 13.2728 3.52701C12.9352 3.18945 12.4774 2.9998 12 2.9998C11.5226 2.9998 11.0648 3.18945 10.7272 3.52701C10.3896 3.86458 10.2 4.32241 10.2 4.7998H9C9 4.00416 9.31607 3.24109 9.87868 2.67848C10.4413 2.11588 11.2044 1.7998 12 1.7998C12.7956 1.7998 13.5587 2.11588 14.1213 2.67848C14.6839 3.24109 15 4.00416 15 4.7998H20.4C20.5591 4.7998 20.7117 4.86302 20.8243 4.97554C20.9368 5.08806 21 5.24067 21 5.3998C21 5.55893 20.9368 5.71155 20.8243 5.82407C20.7117 5.93659 20.5591 5.9998 20.4 5.9998H19.7352L18.18 19.4758C18.1123 20.0607 17.8319 20.6003 17.3923 20.9919C16.9527 21.3835 16.3844 21.5999 15.7956 21.5998H8.2044C7.61562 21.5999 7.04735 21.3835 6.6077 20.9919C6.16806 20.6003 5.88771 20.0607 5.82 19.4758L4.2636 5.9998H3.6C3.45957 5.99985 3.32357 5.95064 3.21569 5.86074C3.10781 5.77084 3.03488 5.64594 3.0096 5.5078L3 5.3998C3 5.24067 3.06321 5.08806 3.17574 4.97554C3.28826 4.86302 3.44087 4.7998 3.6 4.7998H13.8ZM18.5256 5.9998H5.4732L7.0116 19.3378C7.04547 19.6303 7.18573 19.9002 7.40567 20.096C7.62562 20.2919 7.90991 20.4 8.2044 20.3998H15.7956C16.0899 20.3997 16.3739 20.2915 16.5936 20.0956C16.8133 19.8998 16.9534 19.6301 16.9872 19.3378L18.5256 5.9998ZM10.2 8.9998C10.494 8.9998 10.74 9.1858 10.7904 9.4306L10.8 9.5254V16.8754C10.8 17.1646 10.5312 17.3998 10.2 17.3998C9.906 17.3998 9.66 17.2138 9.6096 16.969L9.6 16.8742V9.5266C9.6 9.2362 9.8688 9.001 10.2 9.001V8.9998ZM13.8 8.9998C14.094 8.9998 14.34 9.1858 14.3904 9.4306L14.4 9.5254V16.8754C14.4 17.1646 14.1312 17.3998 13.8 17.3998C13.506 17.3998 13.26 17.2138 13.2096 16.969L13.2 16.8742V9.5266C13.2 9.2362 13.4688 9.001 13.8 9.001V8.9998Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                  <h2 className="h2 text-heading font-semibold capitalize">
                    {myOrganisation?.brandName}
                  </h2>
                </div>
                <div className="flex flex-row lg:hidden">
                  <div
                    className={`${
                      isProfileDetailsSelected
                        ? "border-0 bg-thumb text-[#fff]"
                        : "border-1 border-b-0 border-primary-dark-20 border-solid"
                    }  w-full px-3 py-1`}
                    onClick={() => {
                      setModals((prevState) => ({
                        ...prevState,
                        showPage: false,
                      }));
                      setIsProfileDetailsSelected(true);
                    }}
                  >
                    <p className="text-center">Profile details</p>
                  </div>
                  <div
                    className={`${
                      !isProfileDetailsSelected
                        ? "border-0 bg-thumb text-[#fff]"
                        : "border-1 border-b-0 border-primary-dark-20 border-solid"
                    }  w-full px-3 py-1`}
                    onClick={() => {
                      setModals((prevState) => ({
                        ...prevState,
                        showPage: true,
                      }));
                      setIsProfileDetailsSelected(false);
                    }}
                  >
                    <p className="text-center">Team details</p>
                  </div>
                </div>
              </div>
              <div
                className={`hidden lg:flex px-2 py-4 bg-primary-extra-light border-primary-dark-20 border-1 border-solid w-[95vw] lg:w-263  lg:mr-4 lg:flex  flex-col`}
              >
                <div className="flex mb-6 items-start">
                  <svg
                    style={{ marginTop: "4px" }}
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_2056_7436)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.8448 5.46784C16.8943 5.51719 16.9335 5.57581 16.9603 5.64036C16.9871 5.7049 17.0009 5.77409 17.0009 5.84397C17.0009 5.91385 16.9871 5.98304 16.9603 6.04758C16.9335 6.11212 16.8943 6.17074 16.8448 6.22009L13.6573 9.40759C13.608 9.45707 13.5493 9.49632 13.4848 9.5231C13.4203 9.54988 13.3511 9.56367 13.2812 9.56367C13.2113 9.56367 13.1421 9.54988 13.0776 9.5231C13.013 9.49632 12.9544 9.45707 12.9051 9.40759L11.3113 7.81384C11.2116 7.71409 11.1555 7.57879 11.1555 7.43772C11.1555 7.29664 11.2116 7.16135 11.3113 7.06159C11.4111 6.96184 11.5464 6.9058 11.6874 6.9058C11.8285 6.9058 11.9638 6.96184 12.0636 7.06159L13.2812 8.28028L16.0926 5.46784C16.1419 5.41837 16.2005 5.37912 16.2651 5.35233C16.3296 5.32555 16.3988 5.31177 16.4687 5.31177C16.5386 5.31177 16.6078 5.32555 16.6723 5.35233C16.7368 5.37912 16.7955 5.41837 16.8448 5.46784Z"
                        fill="#7D7C7C"
                      />
                      <path
                        d="M1.0625 14.875C1.0625 14.875 0 14.875 0 13.8125C0 12.75 1.0625 9.5625 6.375 9.5625C11.6875 9.5625 12.75 12.75 12.75 13.8125C12.75 14.875 11.6875 14.875 11.6875 14.875H1.0625ZM6.375 8.5C7.22038 8.5 8.03113 8.16418 8.6289 7.5664C9.22668 6.96863 9.5625 6.15788 9.5625 5.3125C9.5625 4.46712 9.22668 3.65637 8.6289 3.0586C8.03113 2.46083 7.22038 2.125 6.375 2.125C5.52962 2.125 4.71887 2.46083 4.1211 3.0586C3.52332 3.65637 3.1875 4.46712 3.1875 5.3125C3.1875 6.15788 3.52332 6.96863 4.1211 7.5664C4.71887 8.16418 5.52962 8.5 6.375 8.5Z"
                        fill="#7D7C7C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2056_7436">
                        <rect width="17" height="17" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="ml-1 flex justify-between flex-col">
                    <h4 className="text-p1 font-semibold">Registered name</h4>
                    <p className="text-s text-secondary-light capitalize font-semibold">
                      {myOrganisation?.registeredName}
                    </p>
                  </div>
                </div>

                <div className="flex mb-6 items-start">
                  <svg
                    style={{ marginTop: "4px" }}
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5572 20.125C13.5317 18.6204 12.4584 16.6367 12.4584 14.8542C12.4584 13.1004 13.3784 11.5 14.7584 10.5417H14.3751V8.625H16.2917V9.80375C16.7709 9.66958 17.2501 9.58333 17.7292 9.58333C17.8922 9.58333 18.0551 9.58333 18.2084 9.61208V2.875H4.79175V20.125H10.5417V16.7708H12.4584V20.125H14.5572ZM14.3751 4.79167H16.2917V6.70833H14.3751V4.79167ZM8.62508 18.2083H6.70841V16.2917H8.62508V18.2083ZM8.62508 14.375H6.70841V12.4583H8.62508V14.375ZM8.62508 10.5417H6.70841V8.625H8.62508V10.5417ZM8.62508 6.70833H6.70841V4.79167H8.62508V6.70833ZM10.5417 4.79167H12.4584V6.70833H10.5417V4.79167ZM10.5417 8.625H12.4584V10.5417H10.5417V8.625ZM10.5417 14.375V12.4583H12.4584V14.375H10.5417ZM17.7292 11.5C15.9084 11.5 14.3751 13.0429 14.3751 14.8542C14.3751 17.3554 17.7292 21.0833 17.7292 21.0833C17.7292 21.0833 21.0834 17.3554 21.0834 14.8542C21.0834 13.0429 19.5501 11.5 17.7292 11.5ZM17.7292 16.1096C17.0584 16.1096 16.5792 15.5346 16.5792 14.9596C16.5792 14.2888 17.1542 13.8096 17.7292 13.8096C18.3042 13.8096 18.8793 14.375 18.8793 14.9596C18.9751 15.5346 18.4001 16.1096 17.7292 16.1096Z"
                      fill="#7D7C7C"
                    />
                  </svg>

                  <div className="ml-1 flex justify-between flex-col">
                    <h4 className="text-p1 font-semibold">
                      Registered address
                    </h4>
                    <p className="text-s capitalize text-secondary-light font-semibold">
                      {myOrganisation?.registeredAddress}
                    </p>
                  </div>
                </div>

                {/* <div className="flex mb-6">
                  <svg
                    style={{ marginTop: "4px" }}
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.59338 3.66699C4.92532 3.66699 4.28463 3.92222 3.81224 4.37653C3.33985 4.83084 3.07446 5.44702 3.07446 6.08951V6.33298L10.6312 10.2466L18.188 6.33419V6.08951C18.188 5.44702 17.9226 4.83084 17.4502 4.37653C16.9778 3.92222 16.3371 3.66699 15.6691 3.66699H5.59338Z"
                      fill="#7D7C7C"
                    />
                    <path
                      d="M18.188 7.70898L10.9297 11.4675C10.838 11.515 10.7354 11.5399 10.6312 11.5399C10.527 11.5399 10.4245 11.515 10.3327 11.4675L3.07446 7.70898V13.3571C3.07446 13.9996 3.33985 14.6158 3.81224 15.0701C4.28463 15.5244 4.92532 15.7796 5.59338 15.7796H15.6691C16.3371 15.7796 16.9778 15.5244 17.4502 15.0701C17.9226 14.6158 18.188 13.9996 18.188 13.3571V7.70898Z"
                      fill="#7D7C7C"
                    />
                  </svg>

                  <div className="ml-1 flex justify-between flex-col">
                    <h4 className="text-p1 font-semibold">Email address</h4>
                    <p className="text-s text-secondary-light font-semibold">
                      {user?.email}
                    </p>
                  </div>
                </div> */}

                {/* <div className="flex mb-6">
                  <svg
                    style={{ marginTop: "4px" }}
                    width="20"
                    height="22"
                    viewBox="0 0 20 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.9379 15.3393L13.6873 12.2055C13.5337 12.0574 13.3318 11.9784 13.1242 11.9852C12.9167 11.992 12.7197 12.0841 12.575 12.2419L10.6614 14.3286C10.2008 14.2353 9.27482 13.9292 8.32163 12.9211C7.36843 11.9096 7.07976 10.9252 6.99419 10.4402L8.96055 8.41037C9.10962 8.25701 9.19656 8.04817 9.20298 7.82805C9.20939 7.60792 9.13478 7.39379 8.99494 7.23096L6.0402 3.78517C5.9003 3.62183 5.70585 3.52275 5.49816 3.50898C5.29047 3.49521 5.08589 3.56782 4.92788 3.7114L3.19263 5.28931C3.05437 5.43643 2.97186 5.63224 2.96072 5.83959C2.94873 6.05156 2.72003 11.0727 6.39205 14.9679C9.59548 18.3637 13.6082 18.6121 14.7133 18.6121C14.8748 18.6121 14.974 18.607 15.0004 18.6053C15.1959 18.5937 15.3805 18.5058 15.5185 18.3586L17.0059 16.5178C17.1419 16.3508 17.2108 16.1341 17.1981 15.9139C17.1854 15.6937 17.0921 15.4875 16.9379 15.3393Z"
                      fill="#7D7C7C"
                    />
                  </svg>

                  <div className="ml-1 flex justify-between flex-col">
                    <h4 className="text-p1 font-semibold">Phone number</h4>
                    <p className="text-s text-secondary-light font-semibold">
                      +91 {user?.mobile}
                    </p>
                  </div>
                </div> */}

                <div className="flex mb-6">
                  <svg
                    style={{ marginTop: "4px" }}
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.8342 3.03187C6.60091 3.03187 6.37717 3.11695 6.21221 3.26838C6.04725 3.41982 5.95457 3.62521 5.95457 3.83938V15.1445C5.95457 15.3586 6.04725 15.564 6.21221 15.7155C6.37717 15.8669 6.60091 15.952 6.8342 15.952H11.6721V16.7595H6.8342C6.36762 16.7595 5.92014 16.5893 5.59022 16.2865C5.2603 15.9836 5.07495 15.5728 5.07495 15.1445V3.83938C5.07495 3.41105 5.2603 3.00027 5.59022 2.69739C5.92014 2.39452 6.36762 2.22437 6.8342 2.22437H15.6304C16.097 2.22437 16.5445 2.39452 16.8744 2.69739C17.2043 3.00027 17.3897 3.41105 17.3897 3.83938V15.1445C17.3897 15.5728 17.2043 15.9836 16.8744 16.2865C16.5445 16.5893 16.097 16.7595 15.6304 16.7595H14.311V15.952H15.6304C15.8637 15.952 16.0875 15.8669 16.2524 15.7155C16.4174 15.564 16.51 15.3586 16.51 15.1445V3.83938C16.51 3.62521 16.4174 3.41982 16.2524 3.26838C16.0875 3.11695 15.8637 3.03187 15.6304 3.03187H6.8342Z"
                      fill="#7D7C7C"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.59351 5.05073C8.59351 4.94364 8.63984 4.84095 8.72232 4.76523C8.8048 4.68951 8.91667 4.64697 9.03332 4.64697H13.4314C13.5481 4.64697 13.6599 4.68951 13.7424 4.76523C13.8249 4.84095 13.8712 4.94364 13.8712 5.05073C13.8712 5.15781 13.8249 5.2605 13.7424 5.33622C13.6599 5.41194 13.5481 5.45448 13.4314 5.45448H9.03332C8.91667 5.45448 8.8048 5.41194 8.72232 5.33622C8.63984 5.2605 8.59351 5.15781 8.59351 5.05073Z"
                      fill="#7D7C7C"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.83423 7.47309C6.83423 7.36601 6.88057 7.26331 6.96305 7.18759C7.04553 7.11187 7.15739 7.06934 7.27404 7.06934H15.1906C15.3073 7.06934 15.4192 7.11187 15.5016 7.18759C15.5841 7.26331 15.6305 7.36601 15.6305 7.47309C15.6305 7.58017 15.5841 7.68287 15.5016 7.75858C15.4192 7.8343 15.3073 7.87684 15.1906 7.87684H7.27404C7.15739 7.87684 7.04553 7.8343 6.96305 7.75858C6.88057 7.68287 6.83423 7.58017 6.83423 7.47309Z"
                      fill="#7D7C7C"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.83423 9.08808C6.83423 8.981 6.88057 8.8783 6.96305 8.80258C7.04553 8.72686 7.15739 8.68433 7.27404 8.68433H15.1906C15.3073 8.68433 15.4192 8.72686 15.5016 8.80258C15.5841 8.8783 15.6305 8.981 15.6305 9.08808C15.6305 9.19516 15.5841 9.29786 15.5016 9.37358C15.4192 9.44929 15.3073 9.49183 15.1906 9.49183H7.27404C7.15739 9.49183 7.04553 9.44929 6.96305 9.37358C6.88057 9.29786 6.83423 9.19516 6.83423 9.08808Z"
                      fill="#7D7C7C"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.83423 10.7031C6.83423 10.596 6.88057 10.4933 6.96305 10.4176C7.04553 10.3419 7.15739 10.2993 7.27404 10.2993H15.1906C15.3073 10.2993 15.4192 10.3419 15.5016 10.4176C15.5841 10.4933 15.6305 10.596 15.6305 10.7031C15.6305 10.8102 15.5841 10.9128 15.5016 10.9886C15.4192 11.0643 15.3073 11.1068 15.1906 11.1068H7.27404C7.15739 11.1068 7.04553 11.0643 6.96305 10.9886C6.88057 10.9128 6.83423 10.8102 6.83423 10.7031Z"
                      fill="#7D7C7C"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.9914 14.7406C13.2247 14.7406 13.4484 14.6556 13.6134 14.5041C13.7784 14.3527 13.871 14.1473 13.871 13.9331C13.871 13.719 13.7784 13.5136 13.6134 13.3621C13.4484 13.2107 13.2247 13.1256 12.9914 13.1256C12.7581 13.1256 12.5344 13.2107 12.3694 13.3621C12.2045 13.5136 12.1118 13.719 12.1118 13.9331C12.1118 14.1473 12.2045 14.3527 12.3694 14.5041C12.5344 14.6556 12.7581 14.7406 12.9914 14.7406ZM12.9914 15.5481C13.458 15.5481 13.9055 15.378 14.2354 15.0751C14.5653 14.7722 14.7507 14.3615 14.7507 13.9331C14.7507 13.5048 14.5653 13.094 14.2354 12.7911C13.9055 12.4883 13.458 12.3181 12.9914 12.3181C12.5248 12.3181 12.0774 12.4883 11.7474 12.7911C11.4175 13.094 11.2322 13.5048 11.2322 13.9331C11.2322 14.3615 11.4175 14.7722 11.7474 15.0751C12.0774 15.378 12.5248 15.5481 12.9914 15.5481Z"
                      fill="#7D7C7C"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.5517 14.7407H11.6721V18.3745L12.9916 17.7689L14.311 18.3745V14.7407H13.4314V17.068L12.9916 16.8661L12.5517 17.068V14.7407Z"
                      fill="#7D7C7C"
                    />
                  </svg>

                  <div className="ml-1 flex justify-between flex-col">
                    <h4 className="text-p1 font-semibold">GST</h4>
                    <p className="text-s text-secondary-light font-semibold">
                      {myOrganisation?.gstIN}
                    </p>
                  </div>
                </div>

                <div className="flex mb-6">
                  <svg
                    style={{ marginTop: "4px" }}
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_2056_7429)">
                      <path
                        d="M17.2999 0.902884C17.2498 0.769875 17.1536 0.662576 17.0311 0.603309C16.9087 0.544042 16.7695 0.537372 16.6426 0.584684C16.5157 0.631997 16.4107 0.72965 16.3496 0.857328C16.2885 0.985007 16.276 1.13288 16.3145 1.27019C17.1168 3.72807 15.5243 5.56367 14.3101 6.54682L13.8195 5.79749C13.6546 5.54618 13.2858 5.33721 12.9991 5.33353L10.2458 5.34641C9.90948 5.35724 9.58214 5.46475 9.29841 5.65756L1.18547 11.7213C0.996763 11.8628 0.868363 12.0783 0.828382 12.3205C0.788401 12.5628 0.840098 12.812 0.972154 13.0137L4.65899 18.6365C4.93535 19.0554 5.37667 19.0047 5.77048 18.712L13.8834 12.6474C14.1166 12.4715 14.3861 12.0923 14.4811 11.8023L15.343 8.90713C15.438 8.61807 15.3801 8.17713 15.2151 7.92582L14.9163 7.46922C16.5469 6.13717 18.2396 3.78331 17.2999 0.902884ZM13.525 9.51746C13.3756 9.62897 13.2069 9.70801 13.0288 9.75004C12.8507 9.79208 12.6665 9.79629 12.4869 9.76243C12.3072 9.72858 12.1356 9.65733 11.9818 9.55274C11.828 9.44816 11.695 9.3123 11.5905 9.15292C11.3788 8.83041 11.2959 8.43156 11.3598 8.04392C11.4238 7.65628 11.6294 7.31153 11.9316 7.08535C12.1685 6.90843 12.4514 6.81483 12.7406 6.81764C13.0298 6.82045 13.311 6.91951 13.5449 7.101C13.31 7.25197 13.1485 7.34034 13.1182 7.35415C13.0112 7.40856 12.9246 7.50011 12.8728 7.61381C12.8209 7.72751 12.8069 7.85661 12.8329 7.97996C12.8588 8.10331 12.9234 8.21359 13.0159 8.29273C13.1084 8.37187 13.2233 8.41517 13.3419 8.41555C13.4162 8.41555 13.4922 8.39714 13.5639 8.36124C13.7314 8.27655 13.9111 8.17621 14.0985 8.05746C14.1416 8.33368 14.1106 8.61726 14.0092 8.87556C13.9077 9.13386 13.7399 9.35636 13.525 9.51746Z"
                        fill="#7D7C7C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2056_7429">
                        <rect
                          width="17.2726"
                          height="18.4111"
                          fill="white"
                          transform="translate(0.555664 0.529297)"
                        />
                      </clipPath>
                    </defs>
                  </svg>

                  <div className="ml-1 flex justify-between flex-col">
                    <h4 className="text-p1 font-semibold">Brand name</h4>
                    <p className="text-s capitalize text-secondary-light font-semibold">
                      {myOrganisation?.brandName}
                    </p>
                  </div>
                </div>

                {/* <div className="flex mb-6">
                  <svg
                    style={{ marginTop: "4px" }}
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.73607 13.7833C9.04794 14.114 9.55173 14.114 9.86359 13.7833L13.2541 10.1883C13.566 9.8576 13.566 9.32344 13.2541 8.98429C13.1802 8.90568 13.0923 8.84333 12.9955 8.80078C12.8988 8.75823 12.7951 8.73633 12.6904 8.73633C12.5856 8.73633 12.4819 8.75823 12.3852 8.80078C12.2885 8.84333 12.2006 8.90568 12.1266 8.98429L9.29584 11.9773L8.16832 10.7818C7.85645 10.4511 7.35267 10.4511 7.03281 10.7818C6.71294 11.1125 6.72094 11.6466 7.03281 11.9773L8.73607 13.7833Z"
                      fill="#7D7C7C"
                    />
                    <path
                      d="M15.7491 3.75293H4.5539C4.12974 3.75293 3.72295 3.93159 3.42302 4.24961C3.12309 4.56763 2.95459 4.99895 2.95459 5.44869V15.6233C2.95459 16.073 3.12309 16.5044 3.42302 16.8224C3.72295 17.1404 4.12974 17.3191 4.5539 17.3191H15.7491C16.6287 17.3191 17.3484 16.556 17.3484 15.6233V5.44869C17.3484 4.99895 17.1799 4.56763 16.88 4.24961C16.5801 3.93159 16.1733 3.75293 15.7491 3.75293ZM15.7491 14.7754C15.7491 15.2417 15.3893 15.6233 14.9494 15.6233H5.35356C4.91375 15.6233 4.5539 15.2417 4.5539 14.7754V7.14446H15.7491V14.7754Z"
                      fill="#7D7C7C"
                    />
                  </svg>

                  <div className="ml-1 flex justify-between flex-col">
                    <h4 className="text-p1 font-semibold">Domain</h4>
                    <p className="text-s capitalize text-secondary-light font-semibold">
                      {myOrganisation?.domain}
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
            {/* change display down there */}
            <div className="flex lg:hidden px-3 flex-col justify-between w-full my-8">
              {isProfileDetailsSelected ? (
                <div className="px-2 max-w-[100vw] py-4   w-full  mr-4 flex  flex-col">
                  <div className="flex flex-col mb-6 items-start">
                    <label className="text-p1 text-secondary-dark font-semibold mb-2">
                      Registered name
                    </label>
                    <input
                      placeholder="Robert Pvt. Ltd."
                      value={myOrganisation?.registeredName}
                      className="w-full border-1 border-solid border-[#eee] h-14 text-[18px]  pl-2 text-secondary-light font-semibold"
                    />
                  </div>

                  <div className="flex flex-col mb-6 items-start">
                    <label className="text-p1 text-secondary-dark font-semibold mb-2">
                      Registered address
                    </label>
                    <input
                      value={myOrganisation?.registeredAddress}
                      placeholder="12, Block 9, New York"
                      className="w-full border-1 border-solid border-[#eee] h-14 text-[18px]  pl-2 text-secondary-light font-semibold"
                    />
                  </div>

                  <div className="flex flex-col mb-6 items-start">
                    <label className="text-p1 text-secondary-dark font-semibold mb-2">
                      GST
                    </label>
                    <input
                      value={myOrganisation?.gstIN}
                      placeholder="1XXXXXXXXX"
                      className="w-full border-1 border-solid border-[#eee] h-14 text-[18px]  pl-2 text-secondary-light font-semibold"
                    />
                  </div>

                  <div className="flex flex-col mb-6 items-start">
                    <label className="text-p1 text-secondary-dark font-semibold mb-2">
                      Domain
                    </label>
                    <input
                      value={myOrganisation?.domain}
                      placeholder="xyz1222321@123"
                      className="w-full border-1 border-solid border-[#eee] h-14 text-[18px]  pl-2 text-secondary-light font-semibold"
                    />
                  </div>

                  <div className="flex flex-col mb-6 items-start">
                    <label className="text-p1 text-secondary-dark font-semibold mb-2">
                      Website
                    </label>
                    <input
                      value={myOrganisation?.website}
                      placeholder="https://Andrewbew12.com"
                      className="w-full border-1 border-solid border-[#eee] h-14 text-[18px]  pl-2 text-secondary-light font-semibold"
                    />
                  </div>
                </div>
              ) : (
                <div className=" py-4 px-2 ">
                  <div className="w-full flex flex-wrap mt-0">
                    {teaMember.map((member, i) => (
                      <div
                        key={i}
                        className="flex flex-col p-3 w-full relative border-1 mb-6 border-solid border-primary-dark-20 min-h-[273px]"
                      >
                        <div className="flex relative items-center">
                          <div className="flex justify-center items-center bg-neutral-10 w-12 h-12 rounded-full mr-3">
                            {member.photo ? (
                              <img
                                className="w-full h-full rounded-full object-cover"
                                src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/profiles/${member.photo}`}
                                alt="team member"
                              />
                            ) : (
                              <span
                                className={`text-[25px] uppercase font-bold ${
                                  member?.status === "disabled"
                                    ? "text-[#e2e2e1]"
                                    : "text-[#fff]"
                                } `}
                              >
                                {member?.fullName[0]}
                              </span>
                            )}
                          </div>
                          <div className="flex flex-col items-center">
                            <p
                              className={`${
                                member?.status === "disabled"
                                  ? "text-[#c9c7c9]"
                                  : "text-secondary-dark"
                              } font-semibold text-[18px] capitalize text-montserrat `}
                            >
                              {member.fullName}
                              {member.isPCP && (
                                <span className="flex flex-row gap-2 items-center">
                                  <span>
                                    <svg
                                      width="15"
                                      height="15"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M0 9C0 6.61305 0.948212 4.32387 2.63604 2.63604C4.32387 0.948212 6.61305 0 9 0C11.3869 0 13.6761 0.948212 15.364 2.63604C17.0518 4.32387 18 6.61305 18 9C18 11.3869 17.0518 13.6761 15.364 15.364C13.6761 17.0518 11.3869 18 9 18C6.61305 18 4.32387 17.0518 2.63604 15.364C0.948212 13.6761 0 11.3869 0 9H0ZM8.4864 12.852L13.668 6.3744L12.732 5.6256L8.3136 11.1468L5.184 8.5392L4.416 9.4608L8.4864 12.8532V12.852Z"
                                        fill="#FFD600"
                                      />
                                    </svg>
                                  </span>
                                  <p className="text-tertiary-dark pr-3 text-[14px]">
                                    Primary
                                  </p>
                                </span>
                              )}
                            </p>
                          </div>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setModals((prevState) => ({
                                ...prevState,
                                primary: true,
                              }));
                            }}
                            className="absolute top-[10px] right-1"
                          >
                            <svg
                              width="6"
                              height="20"
                              viewBox="0 0 6 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.9974 7.66602C1.71406 7.66602 0.664063 8.71601 0.664063 9.99935C0.664063 11.2827 1.71406 12.3327 2.9974 12.3327C4.28073 12.3327 5.33073 11.2827 5.33073 9.99935C5.33073 8.71601 4.28073 7.66602 2.9974 7.66602ZM2.9974 0.666016C1.71406 0.666016 0.664062 1.71602 0.664062 2.99935C0.664062 4.28268 1.71406 5.33268 2.9974 5.33268C4.28073 5.33268 5.33073 4.28268 5.33073 2.99935C5.33073 1.71602 4.28073 0.666016 2.9974 0.666016ZM2.9974 14.666C1.71406 14.666 0.664063 15.716 0.664063 16.9993C0.664063 18.2827 1.71406 19.3327 2.9974 19.3327C4.28073 19.3327 5.33073 18.2827 5.33073 16.9993C5.33073 15.716 4.28073 14.666 2.9974 14.666Z"
                                fill="#702963"
                              />
                            </svg>
                          </button>
                        </div>
                        <div className="flex flex-col my-2">
                          <label className="text-p1 text-secondary-dark font-semibold mb-2">
                            Email address
                          </label>
                          <input
                            type="email"
                            value={member.email}
                            placeholder="Robertteamhr@gmail.com"
                            className="w-full border-1 border-solid border-[#eee] h-14 text-[18px]  pl-2 text-secondary-light font-semibold"
                            disabled={
                              member?.status === "disabled" ? true : false
                            }
                          />
                        </div>
                        <div className="flex flex-col mb-3">
                          <label className="text-p1 text-secondary-dark font-semibold mb-2">
                            Phone number
                          </label>
                          <input
                            type="tel"
                            value={member.mobile}
                            placeholder="Robertteamhr@gmail.com"
                            className="w-full border-1 border-solid border-[#eee] h-14 text-[18px]  pl-2 text-secondary-light font-semibold"
                            disabled={
                              member?.status === "disabled" ? true : false
                            }
                          />
                        </div>
                        {modals.update && (
                          <button
                            onClick={() =>
                              setModals((prevState) => ({
                                ...prevState,
                                primary: false,
                                update: false,
                              }))
                            }
                            className="bg-primary-dark w-[93px] h-[29px] my-2 font-semibold text-primary-white"
                          >
                            Update
                          </button>
                        )}
                      </div>
                    ))}
                    <button
                      onClick={() =>
                        setModals((prevState) => ({
                          ...prevState,
                          newMember: true,
                        }))
                      }
                      className="w-full mb-4 border-1 box-border border-solid border-primary-dark-20 h-24 flex flex-col justify-evenly items-center"
                    >
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 0C13.2439 0.0573807 8.69882 1.97223 5.33552 5.33552C1.97223 8.69882 0.0573807 13.2439 0 18C0.0573807 22.7561 1.97223 27.3012 5.33552 30.6645C8.69882 34.0278 13.2439 35.9426 18 36C22.7561 35.9426 27.3012 34.0278 30.6645 30.6645C34.0278 27.3012 35.9426 22.7561 36 18C35.9426 13.2439 34.0278 8.69882 30.6645 5.33552C27.3012 1.97223 22.7561 0.0573807 18 0ZM28.2857 19.2857H19.2857V28.2857H16.7143V19.2857H7.71429V16.7143H16.7143V7.71429H19.2857V16.7143H28.2857V19.2857Z"
                          fill="#B6B6B6"
                        />
                      </svg>
                      <p className="text-p1 text-secondary-light">
                        Add a New Team Member
                      </p>
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="hidden lg:block py-4 px-2 bg-primary-extra-light border-primary-dark-20 border-1 border-solid w-921">
              <h2 className="text-h4 ml-2 font-semibold text-heading">
                Team Details
              </h2>
              <div className="w-full flex flex-wrap mt-2">
                <button
                  onClick={() =>
                    setModals((prevState) => ({
                      ...prevState,
                      newMember: true,
                    }))
                  }
                  className="border-1 ml-2 border-solid border-primary-dark-20 w-card h-card flex flex-col justify-evenly items-center"
                >
                  <svg
                    width="72"
                    height="70"
                    viewBox="0 0 72 70"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M36 4.375C27.6769 4.47263 19.7229 7.73052 13.8372 13.4528C7.9514 19.1751 4.60042 26.9081 4.5 35C4.60042 43.0919 7.9514 50.8249 13.8372 56.5472C19.7229 62.2695 27.6769 65.5274 36 65.625C44.3231 65.5274 52.2771 62.2695 58.1628 56.5472C64.0486 50.8249 67.3996 43.0919 67.5 35C67.3996 26.9081 64.0486 19.1751 58.1628 13.4528C52.2771 7.73052 44.3231 4.47263 36 4.375ZM54 37.1875H38.25V52.5H33.75V37.1875H18V32.8125H33.75V17.5H38.25V32.8125H54V37.1875Z"
                      fill="#EBE8E8"
                    />
                  </svg>
                  <p className="text-p1 text-secondary-light">
                    Add a New Team Member
                  </p>
                </button>
                {teaMember?.map((member, i) => (
                  <div
                    key={i}
                    className={`flex relative ${
                      member?.status === "disabled" && "cursor-not-allowed"
                    } justify-start items-center border-1 mt-2 ml-2 border-solid border-primary-dark-20 w-card h-card`}
                  >
                    <div className="flex flex-col ml-[65px]">
                      <div className="flex items-center">
                        <h4
                          className={`${
                            member?.status === "disabled"
                              ? "text-[#c9c7c9]"
                              : "text-secondary-dark"
                          } font-semibold text-h5 mr-2 ml-2`}
                        >
                          {member.fullName}
                        </h4>
                        {member.isPCP && (
                          <p className="flex gap-1 w-[135px] items-center text-p1 font-semibold text-tertiary-dark ">
                            <svg
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 8.5C0 6.24566 0.895533 4.08365 2.48959 2.48959C4.08365 0.895533 6.24566 0 8.5 0C10.7543 0 12.9163 0.895533 14.5104 2.48959C16.1045 4.08365 17 6.24566 17 8.5C17 10.7543 16.1045 12.9163 14.5104 14.5104C12.9163 16.1045 10.7543 17 8.5 17C6.24566 17 4.08365 16.1045 2.48959 14.5104C0.895533 12.9163 0 10.7543 0 8.5H0ZM8.01493 12.138L12.9087 6.02027L12.0247 5.31307L7.85173 10.5275L4.896 8.0648L4.17067 8.9352L8.01493 12.1391V12.138Z"
                                fill="#FFD600"
                              />
                            </svg>
                            Primary
                          </p>
                        )}
                      </div>
                      <div className="flex items-center my-2">
                        <p className="text-p1 text-secondary-light mr-6 ml-2">
                          E-mail address:
                        </p>
                        <p
                          className={`text-p1 font-semibold ${
                            member?.status === "disabled"
                              ? "text-[#c9c7c9]"
                              : "text-secondary-dark"
                          }`}
                        >
                          {member.email}
                        </p>
                      </div>
                      <div className="flex items-center ">
                        <p className="text-p1 text-secondary-light mr-6 ml-2">
                          Phone number:
                        </p>
                        <p
                          className={`text-p1 font-semibold ${
                            member?.status === "disabled"
                              ? "text-[#c9c7c9]"
                              : "text-secondary-dark"
                          }`}
                        >
                          {member.mobile}
                        </p>
                      </div>
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setModals((prevState) => ({
                          ...prevState,
                          primary: true,
                        }));
                        setMemberSelected(member.id);
                      }}
                      className="absolute top-6 right-4"
                      disabled={member?.status === "disabled"}
                    >
                      <svg
                        width="19"
                        height="18"
                        viewBox="0 0 19 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.2812 14.625C11.2812 15.0726 11.0936 15.5018 10.7595 15.8182C10.4255 16.1347 9.97242 16.3125 9.5 16.3125C9.02758 16.3125 8.57451 16.1347 8.24047 15.8182C7.90642 15.5018 7.71875 15.0726 7.71875 14.625C7.71875 14.1774 7.90642 13.7482 8.24047 13.4318C8.57451 13.1153 9.02758 12.9375 9.5 12.9375C9.97242 12.9375 10.4255 13.1153 10.7595 13.4318C11.0936 13.7482 11.2812 14.1774 11.2812 14.625ZM11.2812 9C11.2812 9.44755 11.0936 9.87678 10.7595 10.1932C10.4255 10.5097 9.97242 10.6875 9.5 10.6875C9.02758 10.6875 8.57451 10.5097 8.24047 10.1932C7.90642 9.87678 7.71875 9.44755 7.71875 9C7.71875 8.55245 7.90642 8.12322 8.24047 7.80676C8.57451 7.49029 9.02758 7.3125 9.5 7.3125C9.97242 7.3125 10.4255 7.49029 10.7595 7.80676C11.0936 8.12322 11.2812 8.55245 11.2812 9ZM11.2812 3.375C11.2812 3.82255 11.0936 4.25178 10.7595 4.56824C10.4255 4.88471 9.97242 5.0625 9.5 5.0625C9.02758 5.0625 8.57451 4.88471 8.24047 4.56824C7.90642 4.25178 7.71875 3.82255 7.71875 3.375C7.71875 2.92745 7.90642 2.49822 8.24047 2.18176C8.57451 1.86529 9.02758 1.6875 9.5 1.6875C9.97242 1.6875 10.4255 1.86529 10.7595 2.18176C11.0936 2.49822 11.2812 2.92745 11.2812 3.375Z"
                          fill="#9C9C9C"
                        />
                      </svg>
                    </button>
                    {member.id === memberSelected && (
                      <div
                        onClick={(e) => e.stopPropagation()}
                        className="w-40 h-20 absolute top-6 right-12 bg-primary-extra-light border-1 border-primary-dark-20 border-solid"
                      >
                        <div className="w-4 h-4 absolute flex flex-col justify-between top-2 -right-1"></div>
                        <button
                          disabled={member.isPCP}
                          onClick={() => {
                            !member.isPCP && handleMakePrimary(member.id, member.fullName);
                          }}
                          className="flex items-center justify-center w-full h-1/2 text-h5 font-semibold text-secondary-dark hover:bg-hover"
                          style={{
                            cursor: !member.isPCP ? "pointer" : "not-allowed",
                          }}
                        >
                          <span className="mr-2">
                            <svg
                              width="15"
                              height="14"
                              viewBox="0 0 15 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g opacity="0.5">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0 7C0 5.14348 0.790176 3.36301 2.1967 2.05025C3.60322 0.737498 5.51088 0 7.5 0C9.48912 0 11.3968 0.737498 12.8033 2.05025C14.2098 3.36301 15 5.14348 15 7C15 8.85652 14.2098 10.637 12.8033 11.9497C11.3968 13.2625 9.48912 14 7.5 14C5.51088 14 3.60322 13.2625 2.1967 11.9497C0.790176 10.637 0 8.85652 0 7H0ZM7.072 9.996L11.39 4.95787L10.61 4.37547L6.928 8.66973L4.32 6.6416L3.68 7.3584L7.072 9.99693V9.996Z"
                                  fill="#585858"
                                />
                              </g>
                            </svg>
                          </span>
                          Make Primary
                        </button>
                        {/* <button 
                          onClick={() => {}}
                          className="flex w-full h-1/2 items-center text-h5 font-semibold text-secondary-dark hover:bg-hover"
                        >
                          <span className="mr-2 ml-4">
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g opacity="0.5">
                                <path
                                  d="M5.44188 12.3166L11.25 6.50852L8.49125 3.74977L2.68313 9.5579C2.60317 9.63796 2.54637 9.73817 2.51875 9.8479L1.875 13.1248L5.15125 12.481C5.26125 12.4535 5.36188 12.3967 5.44188 12.3166ZM13.125 4.63352C13.3593 4.39911 13.491 4.08123 13.491 3.74977C13.491 3.41832 13.3593 3.10043 13.125 2.86602L12.1338 1.87477C11.8993 1.64043 11.5815 1.50879 11.25 1.50879C10.9185 1.50879 10.6007 1.64043 10.3663 1.87477L9.375 2.86602L12.1338 5.62477L13.125 4.63352Z"
                                  fill="#585858"
                                />
                              </g>
                            </svg>
                          </span>
                          Update
                        </button> */}
                        <button
                          disabled={member.isPCP}
                          onClick={() =>
                            handleMemberDelete(
                              member?.id,
                              member?.isPCP,
                              member?.status
                            )
                          }
                          className="flex w-full h-1/2  items-center text-h5 font-semibold text-secondary-dark hover:bg-hover "
                          style={{
                            cursor: !member.isPCP ? "pointer" : "not-allowed",
                          }}
                        >
                          <span className="mr-2 ml-4">
                            <svg
                              width="16"
                              height="14"
                              viewBox="0 0 16 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g opacity="0.5">
                                <path
                                  d="M4.00016 11.0833C4.00016 11.725 4.60016 12.25 5.3335 12.25H10.6668C11.4002 12.25 12.0002 11.725 12.0002 11.0833V4.08333H4.00016V11.0833ZM12.6668 2.33333H10.3335L9.66683 1.75H6.3335L5.66683 2.33333H3.3335V3.5H12.6668V2.33333Z"
                                  fill="#585858"
                                />
                              </g>
                            </svg>
                          </span>
                          Disable
                        </button>
                      </div>
                    )}
                    {member.photo ? (
                      <img
                        className="block absolute top-6 left-4 w-12 h-12 rounded-full object-cover"
                        src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/profiles/${member.photo}`}
                        alt={member.name}
                      />
                    ) : (
                      <div className="absolute top-6 left-4 w-12 h-12 flex items-center justify-center rounded-full bg-neutral-50">
                        <span
                          className={`text-[25px] uppercase font-bold ${
                            member?.status === "disabled"
                              ? "text-[#e2e2e1]"
                              : "text-[#fff]"
                          } `}
                        >
                          {member?.fullName[0]}
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {modals.newMember && (
            <DialogBox show={modals.newMember} zindex={modals.newMember}>
              <div className="bg-primary-white flex flex-col justify-between relative w-full max-w-[430px] lg:max-w-none lg:w-pop-up max-h-[100vh] h-[100%] lg:h-[545px] px-4 lg:p-8 rounded-t-xl rounded-tr-xl lg:rounded-t-none">
                <h4 className="text-h4 text-tertiary-dark font-bold">
                  Add a New Team Member
                </h4>
                {/* <h5 className="text-heading mt-4 text-secondary-dark font-bold">
                  Enter details for the new team member
                </h5> */}
                <div className="lg:hidden flex justify-center">
                  <div className="flex justify-center items-center lg:absolute top-48 lg:top-40 right-4 lg:right-12 w-28 lg:w-40 h-28 lg:h-40 rounded-full bg-neutral-10 mt-3">
                    <p className="w-10/12 text-center text-secondary-light text-p1">
                      Add Profile Picture
                    </p>
                  </div>
                </div>

                {/* body */}

                <form
                  onSubmit={handleSubmit(handleNewMember)}
                  className="flex mt-4 flex-col justify-between"
                  autoComplete="off"
                >
                  <>
                    <label className="text-p1 font-semibold text-secondary-dark -mb-2">
                      Name
                    </label>
                    <input
                      type="name"
                      name="name"
                      placeholder="Enter Name"
                      autoComplete="off"
                      className="pl-2 mt-4 outline-primary-light font-semibold w-full lg:w-40 h-14 bg-primary-white border-1 border-[#eee] border-solid"
                      {...register("name", {
                        required: true,
                        maxLength: 80,
                      })}
                    />
                    <p className="m-0 text-[13px] text-[red]">
                      {errors.name && "Please enter the Full Name."}
                    </p>
                  </>
                  <>
                    <label className="text-p1 mt-4 font-semibold text-secondary-dark -mb-2">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      name="tel"
                      placeholder="Enter Mobile Number"
                      autoComplete="off"
                      className="pl-2  mt-4 outline-primary-light font-semibold w-full lg:w-48 h-14 bg-primary-white border-1 border-[#eee] border-solid"
                      {...register("tel", {
                        required: true,
                        minLength: 10,
                        maxLength: 10,
                      })}
                    />
                    <p className="m-0 text-[13px] text-[red]">
                      {errors.tel && "Please enter Valid Mobile Number."}
                    </p>
                  </>
                  <>
                    <label className="text-p1 mt-4 font-semibold text-secondary-dark -mb-2">
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter Email Address"
                      autoComplete="off"
                      className="realative pl-2 mt-4 mb-8 outline-primary-light font-semibold w-full lg:w-64 h-12 bg-primary-white border-1 border-[#eee] border-solid"
                      {...register("email", {
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                    />
                    <p className="mb-4 md:mb-6 mt-[-16px] md:mt-[-32px] text-[13px] text-[red]">
                      {errors.email && "Please enter Valid Email Address."}
                    </p>
                  </>
                  <div className=" flex justify-end lg:hidden">
                    <BtnSmall>
                      {" "}
                      {miniLoader ? (
                        <div className="flex">
                          <svg
                            className="animate-spin ml-1 mr-3 h-5 w-5 "
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              class="opacity-20"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              stroke-width="4"
                            ></circle>
                            <path
                              class="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Processing...
                        </div>
                      ) : (
                        "Save"
                      )}
                    </BtnSmall>
                  </div>
                  <div className="hidden lg:flex  items-center justify-end text-right">
                    <BtnSmallWhite
                      onClick={() =>
                        setModals((prevState) => ({
                          ...prevState,
                          newMember: false,
                        }))
                      }
                    >
                      Cancel
                    </BtnSmallWhite>
                    <span className="mx-3"></span>
                    {!isValid ? (
                      <BtnSmallDisabled disabled={!isValid}>
                        Save
                      </BtnSmallDisabled>
                    ) : (
                      <BtnSmall
                        type="submit"
                        onClick={() => {
                          handleNewMember();
                        }}
                      >
                        {miniLoader ? (
                          <div className="flex">
                            <svg
                              className="animate-spin ml-1 mr-3 h-5 w-5 "
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                class="opacity-20"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                              ></circle>
                              <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            Processing...
                          </div>
                        ) : (
                          "Save"
                        )}
                      </BtnSmall>
                    )}
                  </div>
                </form>
                <div className="hidden lg:flex justify-center items-center absolute top-48 lg:top-40 right-4 lg:right-12 w-28 lg:w-40 h-28 lg:h-40 rounded-full bg-neutral-10">
                  <p className="w-10/12 text-center text-secondary-light text-p1">
                    Add a profile picture
                  </p>
                </div>
                <button
                  onClick={() => {
                    setModals((prevState) => ({
                      ...prevState,
                      newMember: false,
                    }));
                    reset();
                  }}
                  className="absolute top-5 lg:top-8 right-4 lg:right-8"
                >
                  <svg
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2176 12.8188C10.9475 13.0888 10.5813 13.2405 10.1994 13.2405C9.81747 13.2405 9.45122 13.0888 9.18117 12.8188L5.99997 9.18281L2.81877 12.8176C2.68545 12.9531 2.52663 13.0609 2.35145 13.1347C2.17628 13.2085 1.98823 13.2469 1.79814 13.2477C1.60805 13.2485 1.41969 13.2116 1.24392 13.1392C1.06815 13.0668 0.908454 12.9604 0.77404 12.8259C0.639626 12.6915 0.533155 12.5318 0.460769 12.3561C0.388382 12.1803 0.351512 11.9919 0.352286 11.8018C0.35306 11.6118 0.391462 11.4237 0.465278 11.2485C0.539094 11.0734 0.646862 10.9145 0.782367 10.7812L4.09197 7.00121L0.781167 3.21881C0.645662 3.0855 0.537894 2.92667 0.464078 2.7515C0.390263 2.57633 0.35186 2.38827 0.351086 2.19818C0.350312 2.00809 0.387182 1.81973 0.459569 1.64397C0.531955 1.4682 0.638426 1.3085 0.77284 1.17409C0.907255 1.03967 1.06695 0.933201 1.24272 0.860814C1.41849 0.788427 1.60685 0.751557 1.79694 0.752331C1.98703 0.753105 2.17508 0.791508 2.35025 0.865323C2.52543 0.939139 2.68425 1.04691 2.81757 1.18241L5.99997 4.81961L9.18117 1.18241C9.31448 1.04691 9.47331 0.939139 9.64848 0.865323C9.82365 0.791508 10.0117 0.753105 10.2018 0.752331C10.3919 0.751557 10.5802 0.788427 10.756 0.860814C10.9318 0.933201 11.0915 1.03967 11.2259 1.17409C11.3603 1.3085 11.4668 1.4682 11.5392 1.64397C11.6116 1.81973 11.6484 2.00809 11.6476 2.19818C11.6469 2.38827 11.6085 2.57633 11.5347 2.7515C11.4608 2.92667 11.3531 3.0855 11.2176 3.21881L7.90797 7.00121L11.2176 10.7812C11.3515 10.915 11.4577 11.0738 11.5301 11.2486C11.6026 11.4234 11.6399 11.6108 11.6399 11.8C11.6399 11.9893 11.6026 12.1766 11.5301 12.3514C11.4577 12.5263 11.3515 12.6851 11.2176 12.8188Z"
                      fill="black"
                      fillOpacity="0.53"
                    />
                  </svg>
                </button>
              </div>
            </DialogBox>
          )}

          {modals.makePrimary && (
            <DialogBox show={modals.makePrimary}>
              <PopUp
                onClick={() =>
                  setModals((prevState) => ({
                    ...prevState,
                    makePrimary: false,
                  }))
                }
                onClose={() =>
                  setModals((prevState) => ({
                    ...prevState,
                    makePrimary: false,
                  }))
                }
                button="Okay!"
                title="Primary contact has been changed"
                main={`${modals?.username} is your new primary contact.`}
                body="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget utvest-ibulum bibendum egestas. Enim mi arcu convallis enim purus interdum. Ut neque at adipiscing tortor facilisis sagittis libero. Ac bibendum vel blandit."
              />
            </DialogBox>
          )}

          {modals.deleteMember && (
            <DialogBox show={modals.deleteMember}>
              <PopUp
                onClick={() =>{
                  setModals((prevState) => ({
                    ...prevState,
                    makePrimary: false,
                    deleteMember: false,
                  }));
                  window.location.reload();
                }}
                onClose={() =>{
                  setModals((prevState) => ({
                    ...prevState,
                    deleteMember: false,
                  }));
                  window.location.reload();
                }}
                button="Okay!"
                title="Team member deleted!"
                main="User has been removed from your team"
                body="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget utvest-ibulum bibendum egestas. Enim mi arcu convallis enim purus interdum. Ut neque at adipiscing tortor facilisis sagittis libero. Ac bibendum vel blandit."
              />
            </DialogBox>
          )}
          {modals.memberAdded && (
            <DialogBox show={modals.memberAdded}>
              <div className="mb-10">
                <PopUp
                  height={true}
                  onClick={() =>
                    setModals((prevState) => ({
                      ...prevState,
                      makePrimary: false,
                      memberAdded: false,
                    }))
                  }
                  onClose={() =>
                    setModals((prevState) => ({
                      ...prevState,
                      memberAdded: false,
                    }))
                  }
                  button="Back to Profile"
                  title="Confirmation"
                  main="A New Member Added to Team"
                  body="A New Member Added to Team"
                />
              </div>
            </DialogBox>
          )}
          {modals.deleteImage && (
            <DialogBox show={modals.deleteImage}>
              <PopUp
                onClick={() => deletePicture()}
                onClose={() =>
                  setModals((prev) => ({ ...prev, deleteImage: false }))
                }
                button="Okay!"
                title="Image Deleted Successfully!"
                main="Logo for the organisation has been deleted"
                body="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget utvest-ibulum bibendum egestas. Enim mi arcu convallis enim purus interdum. Ut neque at adipiscing tortor facilisis sagittis libero. Ac bibendum vel blandit."
              />
            </DialogBox>
          )}

          <ToastContainer
            position="top-right"
            autoClose={3000}
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
          />
        </>
      )}
    </Page>
  );
}
