import { useState, useEffect } from "react";
import {
  Page,
  Navbar,
  BtnBlock,
  DialogBox,
  BtnSmall,
  BtnSmallDisabled,
} from "../components";
import Loader from "../components/loader";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as ROUTES from "../constant/routes";
import { ToastContainer, toast } from "react-toastify";

import {
  getBooksforAssign,
  allOrganisationUser,
  allDepartements,
  getBooksfromCart,
  getUsersfromCart,
  getDepartmentsfromCart,
  addBooktoCart,
  addUserstoCart,
  addDepartmentstoCart,
  deleteBooksfromCart,
  deleteUsersfromCart,
  deleteDepartmentsfromCart,
  emptyCart,
  otpVerify,
  genrateOrderIdforIndiviual,
  genrateOrderIdforDepartment,

  //SETS API
  addSetstoCart,
  getsetsforAssign,
  getSetsfromCart,
  deleteSetsfromCart,
  genrateSetOrderIdforIndiviual,
  genrateSetOrderIdforDepartment,
  otpVerifyforSets,
} from "../assets/Js/script";
import Timeout from "../helpers/Timeout";
import ReactTooltip from "react-tooltip";
import { queryByLabelText } from "@testing-library/react";

export default function Catalogue() {
  const [modals, setModals] = useState({
    expandedMenu: false,
    generateIds: false,
    summary: false,
    assignmentSuccessful: false,
    otpVerify: false,
  });

  const notify = (message) => toast(message);

  const [dropdowns, setDropdowns] = useState({
    users: false,
    sets: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [sortBy, setSortBy] = useState("Individual");
  const navigation = useNavigate();
  const [booksInCart, setBooksInCart] = useState(null);
  const [bookSearch, setBookSearch] = useState("");
  const [usersInCart, setUsersInCart] = useState(null);
  const [usersSearch, setUsersSearch] = useState("");
  const [depInCart, setDepInCart] = useState(null);
  const [depSearch, setDepSearch] = useState("");
  const [books, setBooks] = useState(null);
  const [users, setUsers] = useState(null);
  const [department, setDepartment] = useState(null);
  const [bookData, setBookData] = useState(null);
  const [carousel, setCarousel] = useState(0);
  const [dataDelete, setDataDelete] = useState({
    book: false,
    user: false,
    dep: false,
    set: false,
  });

  ///SETS
  const [sortSet, setSortSet] = useState("Books");

  const [setsData, setSetsData] = useState(null);

  const [setsInCart, setSetsInCart] = useState(null);
  const [setSearch, setSetSearch] = useState("");
  const [sets, setSets] = useState(null);

  // dropped books

  const [droppedSets, setDroppedSets] = useState([]);

  // dropped books

  const [droppedBooks, setDroppedBooks] = useState([]);

  // dropped users

  const [droppedUsers, setDropppedUsers] = useState([]);

  // dropped department

  const [droppedDep, setDropppedDep] = useState([]);

  // access ids

  const [disabled, setDisabled] = useState({
    dragDrop: false,
    bookAssignment: false,
  });

  const [accessIdSelected, setAccessIdSelected] = useState(true);
  const [generateAccessIds, setGenerateAccessIds] = useState(false);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (booksInCart || usersInCart || depInCart) {
    }
  }, []);

  function handleIdType({ target }) {
    const { name } = target;
    setAccessIdSelected(name === "access_id" ? true : false);
  }

  function handleSelection(book) {
    setSelected((prevState) => {
      if (prevState.includes(book.id)) {
        return prevState.filter((item) => item !== book.id);
      } else {
        return [...prevState, book.id];
      }
    });
  }

  function handleDragStart(e, book, isUser) {
    e.dataTransfer.setData("text/plain", JSON.stringify([book, isUser]));
    e.dataTransfer.dropEffect = "move";
  }

  /////////////////////////////////////////////////////

  function handleDrop(e) {
    const data = JSON.parse(e.dataTransfer.getData("text/plain"));
    if (data[1] === 1) {
      setDroppedBooks((prevState) => [...prevState, { ...data[0] }]);
      setBooks((prevState) => [
        ...prevState.filter((item) => item.parentId !== data[0].parentId),
      ]);
      addbookincart(data[0].bookId, data[0].validity);
    } else if (data[1] === 2) {
      setDropppedUsers((prevState) => [...prevState, { ...data[0] }]);
      setUsers((prevState) => [
        ...prevState.filter((item) => item.id !== data[0].id),
      ]);
      addusersincart(data[0].id);
    } else if (data[1] === 3) {
      setDropppedDep((prevState) => [...prevState, { ...data[0] }]);
      setDepartment((prevState) => [
        ...prevState.filter((item) => item.id !== data[0].id),
      ]);
      addDepsincart(data[0].id);
    } else {
      setDroppedSets((prevState) => [...prevState, { ...data[0] }]);
      setSets((prevState) => [
        ...prevState.filter((item) => item.id !== data[0].id),
      ]);
      addSetincart(data[0].id, data[0].validity);
    }
  }

  // drag and drop users

  function handleDragStartUser(e, user, isUser) {
    e.dataTransfer.setData("text/plain", JSON.stringify([user, isUser]));

    e.dataTransfer.dropEffect = "move";
  }
  function handleDragStartDep(e, user, isDep) {
    e.dataTransfer.setData("text/plain", JSON.stringify([user, isDep]));

    e.dataTransfer.dropEffect = "move";
  }
  ///////////// api ///////////////
  useEffect(() => {
    async function getBookfromCart() {
      const { response, error } = await getBooksfromCart();

      if (response.status === "success") {
        setBooksInCart({ ...response.data });
        // filterBookData(response.data);
        filterBookDatafromcart(
          [...response.data.books],
          [...response.data.bookData]
        );
      }
    }
    getBookfromCart();
    async function getSetFromCart() {
      const { response, error } = await getSetsfromCart();

      if (response.status === "success") {
        setSetsInCart({ ...response.data });
        // filterBookData(response.data);
        filterSetDatafromcart(
          [...response.data.sets],
          [...response.data.setData]
        );
      }
    }
    getSetFromCart();

    async function getUserfromCart() {
      const { response, error } = await getUsersfromCart();
      if (response.status === "success") {
        setUsersInCart([...response.data.users]);
      }
    }
    getUserfromCart();

    async function getDepfromCart() {
      const { response, error } = await getDepartmentsfromCart();
      if (response.status === "success") {
        setDepInCart([...response.data.departments]);
        if (response.departments > 0) {
          setSelected("Department");
        }
      }
    }
    getDepfromCart();

    async function bookforAssign() {
      setIsLoading(true);
      const { response, error } = await getBooksforAssign();
      if (response.status === "success") {
        // getBooksforAssigning(response.data);

        mergeArrays(
          [...response.data.availableCodes],
          [...response.data.books]
        );
        setIsLoading(false);
      }
    }
    bookforAssign();
    async function setsforAssign() {
      const { response, error } = await getsetsforAssign();
      if (response.status === "success") {
        // getBooksforAssigning(response.data);
        mergeArraysforset(
          [...response.data.availableCodes],
          [...response.data.sets]
        );
      }
    }
    setsforAssign();
    async function getalluser() {
      setIsLoading(true);
      const { response, error } = await allOrganisationUser("active");
      if (response.status === "success") {
        setIsLoading(false);
        setUsers([...response.data.orgUsers]);
      }
    }
    getalluser();

    async function getalldep() {
      const { response, error } = await allDepartements();
      if (response.status === "success") {
        setDepartment([...response.data.departments]);
      }
    }
    getalldep();
  }, [
    dataDelete.book,
    dataDelete.user,
    dataDelete.dep,
    dataDelete.set,
    droppedBooks,
    droppedUsers,
    droppedDep,
    droppedSets,
  ]);

  async function addbookincart(id, validity) {
    const { response, error } = await addBooktoCart(id, validity);
    if (response.status === "success") {
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
  }
  async function addSetincart(id, validity) {
    const { response, error } = await addSetstoCart(id, validity);
    if (response.status === "success") {
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
  }
  async function addusersincart(id) {
    const { response, error } = await addUserstoCart(id);
    if (response.status === "success") {
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
  }
  async function addDepsincart(id) {
    const { response, error } = await addDepartmentstoCart(id);
    if (response.status === "success") {
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
  }

  async function deleteBookfromcart(id) {
    const { response, error } = await deleteBooksfromCart(id);
    setDataDelete((prev) => ({ ...prev, book: !dataDelete.book }));
  }
  async function deleteSetfromcart(id) {
    const { response, error } = await deleteSetsfromCart(id);
    setDataDelete((prev) => ({ ...prev, set: !dataDelete.set }));
  }

  async function deleteUserfromcart(id) {
    const { response, error } = await deleteUsersfromCart(id);
    setDataDelete((prev) => ({ ...prev, user: !dataDelete.user }));
  }
  async function deleteDepfromcart(id) {
    const { response, error } = await deleteDepartmentsfromCart(id);
    setDataDelete((prev) => ({ ...prev, dep: !dataDelete.dep }));
  }

  async function emptyallCart() {
    const { response, error } = await emptyCart();
  }

  async function createOrderIdIndiviual() {
    setOtp([...otp.map((v) => "")]);
    const { response, error } = await genrateOrderIdforIndiviual();
    if (response.status === "success") {
      setModals((prevState) => ({
        ...prevState,
        summary: false,
        otpVerify: true,
      }));
      setOtp([...otp.map((v) => "")]);
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
  }

  async function createsetOrderIdIndiviual() {
    setOtp([...otp.map((v) => "")]);
    const { response, error } = await genrateSetOrderIdforIndiviual();
    if (response.status === "success") {
      setModals((prevState) => ({
        ...prevState,
        summary: false,
        otpVerify: true,
      }));
      setOtp([...otp.map((v) => "")]);
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
  }
  async function createOrderIdDepartment() {
    const { response, error } = await genrateOrderIdforDepartment();
    if (response.status === "success") {
      setModals((prevState) => ({
        ...prevState,
        summary: false,
        otpVerify: true,
      }));
      setOtp([...otp.map((v) => "")]);
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
  }

  async function createsetOrderIdDepartment() {
    const { response, error } = await genrateSetOrderIdforDepartment();
    if (response.status === "success") {
      setModals((prevState) => ({
        ...prevState,
        summary: false,
        otpVerify: true,
      }));
      setOtp([...otp.map((v) => "")]);
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
  }
  async function verifyOtp() {
    const { response, error } = await otpVerify(otp.join(""));
    if (response.status === "success") {
      setModals((prev) => ({
        ...prev,
        assignmentSuccessful: true,
        otpVerify: false,
      }));
      emptyallCart();
    } else {
      setOtp([...otp.map((v) => "")]);
      notify(response?.message ? response?.message : "Something went wrong");
    }
  }
  async function verifyOtpset() {
    const { response, error } = await otpVerifyforSets(otp.join(""));
    if (response.status === "success") {
      setModals((prev) => ({
        ...prev,
        assignmentSuccessful: true,
        otpVerify: false,
      }));
      emptyallCart();
    } else {
      setOtp([...otp.map((v) => "")]);
      notify(response?.message ? response?.message : "Something went wrong");
    }
  }
  ////////////// api ///////////////////

  // useEffect(() => {
  //   if (books !== null && booksInCart !== null) {
  //     setBooks((prev) => [
  //       ...prev.filter((elm) => elm.parentId !== booksInCart.bookId),
  //     ]);
  //   }
  // }, [books]);

  const mergeArrays = (arr1, arr2) => {
    let res = [];

    res = arr1.map((obj) => {
      const index = arr2.findIndex((el) => el?.parentId == obj?.bookId);

      const { title, imageCoverFront, Bauthors, backgroundColor } =
        index !== -1 ? arr2[index] : {};

      return {
        ...obj,

        title,
        imageCoverFront,
        Bauthors,
        backgroundColor,
      };
    });

    setBooks([...res]);
    return res;
  };

  const mergeArraysforset = (arr1, arr2) => {
    let res = [];
    res = arr1.map((obj) => {
      const index = arr2.findIndex((el) => el?.id == obj?.setId);

      const { title, cover, id } = index !== -1 ? arr2[index] : {};

      return {
        ...obj,

        title,
        cover,
        id,
      };
    });
    setSets([...res]);

    return res;
  };

  ////////////////////////////////////////////////////////////

  // function filterBookData(data) {
  //   const test = [];
  //   if (data) {
  //     for (let i = 0; i < data?.books?.length; i++) {
  //       for (let j = 0; j < data?.bookData?.length; j++) {
  //         if (data?.books?.bookId === data?.bookData?.parentId) {
  //           test.push({ ...data.books[i], ...data.bookData[j] });
  //         } else console.log("not working!");
  //       }
  //     }
  //   }
  //   return test;
  // }

  const filterBookDatafromcart = (arr1, arr2) => {
    let res = [];

    res = arr1.map((obj) => {
      const index = arr2.findIndex((el) => el?.parentId == obj?.bookId);

      const { title, imageCoverFront, Bauthors, backgroundColor } =
        index !== -1 ? arr2[index] : {};

      return {
        ...obj,

        title,
        imageCoverFront,
        Bauthors,
        backgroundColor,
      };
    });
    setBookData([...res]);
    setUpdate(true);
    return true;
  };

  const filterSetDatafromcart = (arr1, arr2) => {
    let res = [];

    res = arr1.map((obj) => {
      const index = arr2.findIndex((el) => el?.parentId == obj?.bookId);

      const { title, cover } = index !== -1 ? arr2[index] : {};

      return {
        ...obj,

        title,
        cover,
      };
    });
    setSetsData([...res]);
    setUpdate(true);
    return true;
  };

  /////////////////////////////////////////////////////////////////////////////////////
  // function getBooksforAssigning(data) {
  //   const test = [];

  //   if (data) {
  //     for (let i = 0; i < data.availableCodes.length; i++) {
  //       for (let j = 0; j < data.books.length; j++) {
  //         if (data.availableCodes.bookId === data.books.parentId) {
  //           test.push({ ...data.availableCodes[i], ...data.books[j] });
  //         } else console.log("not working!");
  //       }
  //     }
  //   }
  //   // setBooks([...test]);
  // }

  // generate orderid

  function generateOrderId() {
    if (sortBy === "Individual" && usersInCart?.length > 0) {
      createOrderIdIndiviual();
    } else if (sortBy === "Department" && depInCart?.length > 0) {
      createOrderIdDepartment();
    }
  }
  function generateOrderIdforSets() {
    if (sortBy === "Individual" && usersInCart?.length > 0) {
      createsetOrderIdIndiviual();
    } else if (sortBy === "Department" && depInCart?.length > 0) {
      createsetOrderIdDepartment();
    }
  }

  const [otp, setOtp] = useState(new Array(6).fill(""));

  function handleChnges(data, index) {
    if (isNaN(data.value)) {
      return false;
    }
    setOtp([...otp.map((d, idx) => (idx === index ? data.value : d))]);

    // for AutoFocus
    if (data.nextSibling) {
      data.nextSibling.focus();
    }
  }

  // disable the assets

  function filterUser(id) {
    if (usersInCart?.length > 0) {
      for (let j = 0; j < usersInCart.length; j++) {
        if (usersInCart[j].orgUserId === id) {
          return true;
        }
      }
      return false;
    }
  }

  function filterDep(id) {
    if (depInCart?.length > 0) {
      for (let j = 0; j < depInCart.length; j++) {
        if (depInCart[j].departmentId === id) {
          return true;
        }
      }
      return false;
    }
  }

  function filterbook(book) {
    if (bookData?.length > 0) {
      for (var j = 0; j < books?.length; j++) {
        if (
          bookData[j]?.bookId === book?.bookId &&
          bookData[j]?.validity == book?.validity
        ) {
          return true;
        }
      }
      return false;
    }
  }

  function filterset(book) {
    if (setsData?.length > 0) {
      for (var j = 0; j < books?.length; j++) {
        if (
          setsData[j]?.bookId === book?.bookId &&
          setsData[j]?.validity == book?.validity
        ) {
          return true;
        }
      }
      return false;
    }
  }

  /// --------carousel-----------////

  function handleLeftSlide() {
    // setCarousel(curr => curr-20);
  }

  function handleRightSlide() {
    if (carousel !== 0) {
      // setCarousel(curr => curr+20);
    }
  }

  useEffect(() => {
    if (depInCart?.length > 0) {
      setSortBy("Department");
    } else {
      setSortBy("Individual");
    }
  }, [dataDelete, dataDelete.dep]);

  useEffect(() => {
    function handleClose() {
      setDropdowns((prev) => ({ ...prev, users: false, sets: false }))
    }
    ///--------------------
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, []);


  return (
    <Page active="wallet">
      <Navbar />
      {isLoading ? (
        <Loader show={isLoading} />
      ) : (
        <>
          <h1 className="text-montserrat text-heading text-h1 font-semibold text-left">
            Assignment
          </h1>
          <div className="flex justify-between my-5 max-w-[1200px] gap-x-6">
            <div
              className={`${disabled.bookAssignment && "opacity-[0.5]"
                } py-[26px] px-[18px] w-[375px] bg-[rgba(255,255,255,0.9)] shadow-[0px_5px_10px_rgba(32,29,31,0.11)] rounded-[5px]`}
            >
              <div className="flex justify-between items-center">
                <h2 className="text-montserrat font-semibold text-h3 text-heading ">
                  Books/ Sets
                </h2>
                <div className="flex items-center">
                  <label className="text-[14px] font-semibold text-secondary-light mr-2">
                    Filter by
                  </label>

                  <button
                    onClick={(e) => {
                      setDropdowns((prevState) => ({
                        ...prevState,
                        sets: !dropdowns.sets,
                      }));
                      e.stopPropagation();
                    }}
                    className="relative flex px-2 justify-between items-center w-[110px] h-[29px] bg-neutral-10 text-secondary-dark font-semibold text-[15px]"
                  >
                    {sortSet}
                    <span className="ml-[3px]">
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="18" height="15" rx="2" fill="#FFD600" />
                        <path d="M9.5 11L5 5H14L9.5 11Z" fill="#7A7976" />
                      </svg>
                    </span>
                    {dropdowns.sets && (
                      <div
                        onClick={(e) => {
                          setDropdowns((prevState) => ({
                            ...prevState,
                            set: false,
                          }));
                          e.stopPropagation();
                        }}
                        className="absolute top-[31px] left-0 w-full h-fit bg-primary-white border-[rgba(201,201,201,0.37)] border-solid border-[0.5px]"
                      >
                        <ReactTooltip
                          place="right"
                          // backgroundColor="#737272a"
                          type="success"
                          effect="solid"
                          offset={{ bottom: 8 }}
                          delayShow="150"
                        />
                        <button
                          onClick={() => {
                            !(setsData?.length > 0) && setSortSet("Books");
                            setDropdowns((prevState) => ({
                              ...prevState,
                              sets: false,
                            }));
                          }}
                          className={`w-full font-medium text-[14px] text-secondary-light ${setsData?.length > 0
                              ? "bg-[#f0f0f0] cursor-not-allowed text-[#aeaeb669]"
                              : ""
                            } hover:bg-[#f0f0f0] h-[33.33%] text-left pl-[10px]`}
                          data-tip={`${setsData?.length > 0
                              ? "You can select one at time"
                              : ""
                            }`}
                        >
                          Books
                        </button>
                        <button
                          onClick={() => {
                            !(bookData?.length > 0) && setSortSet("Sets");
                            setDropdowns((prevState) => ({
                              ...prevState,
                              sets: false,
                            }));
                          }}
                          className={`w-full font-medium text-[14px] text-secondary-light ${bookData?.length > 0
                              ? "bg-[#f0f0f0] cursor-not-allowed text-[#aeaeb669]"
                              : ""
                            } hover:bg-[#f0f0f0] h-[33.33%] text-left pl-[10px]`}
                          data-tip={`${bookData?.length > 0
                              ? "You can select one at time"
                              : ""
                            }`}
                        >
                          Sets
                        </button>
                        {/* <button
                      onClick={() => {
                        setSortBy("Groups");
                      }}
                      className="w-full font-medium text-[14px] text-secondary-light hover:bg-[#f0f0f0] h-[33.33%] text-left pl-[10px]"
                    >
                      Groups
                    </button> */}
                      </div>
                    )}
                  </button>
                </div>
              </div>
              <form className="my-[21px] flex justify-between items-center border-1 border-solid border-[#eee] w-full h-[48px]">
                <button
                  disabled={disabled.bookAssignment}
                  className="py-4 pl-4 pr-2"
                >
                  <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 17.5L13.1333 13.6333M15.2222 8.61111C15.2222 12.5385 12.0385 15.7222 8.11111 15.7222C4.18375 15.7222 1 12.5385 1 8.61111C1 4.68375 4.18375 1.5 8.11111 1.5C12.0385 1.5 15.2222 4.68375 15.2222 8.61111Z"
                      stroke="#702963"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                {sortSet === "Books" && (
                  <input
                    disabled={disabled.bookAssignment}
                    className=" pr-[18px] outline-0 border-0 w-[calc(100%-24px)] h-full font-semibold text-heading  "
                    placeholder="Search"
                    value={bookSearch}
                    onChange={(e) => {
                      setBookSearch(e.target.value);
                    }}
                  />
                )}
                {sortSet === "Sets" && (
                  <input
                    disabled={disabled.bookAssignment}
                    className=" pr-[18px] outline-0 border-0 w-[calc(100%-24px)] h-full font-semibold text-heading  "
                    placeholder="Search"
                    value={setSearch}
                    onChange={(e) => {
                      setSetSearch(e.target.value);
                    }}
                  />
                )}
              </form>
              {sortSet === "Books" && (
                <div className="gap-3 grid grid-cols-[repeat(2,50%)] my-[18px]">
                  {books
                    ?.filter((value) => {
                      if (bookSearch === "") {
                        return value;
                      } else if (
                        value.title
                          .toLowerCase()
                          .includes(bookSearch.toLowerCase())
                      ) {
                        return value;
                      }
                    })
                    .map((book, idx) => (
                      <div
                        key={idx}
                        onDragStart={(e) => handleDragStart(e, book, 1)}
                        draggable={!disabled.bookAssignment}
                        className={`${filterbook(book) &&
                          "cursor-not-allowed bg-[#c2c2bd57]"
                          } flex flex-col justify-between leading-[19px] w-[160px] h-[197px] p-2 shadow-[4px_4px_4px_rgba(224,224,224,0.25)] rounded-[2px]`}
                      >
                        <div
                          style={{ background: book?.backgroundColor }}
                          className="h-[108px] flex justify-center items-center bg-neutral-10 relative"
                        >
                          <img
                            src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${book.imageCoverFront}`}
                            alt="front cover h-full "
                            className="h-[88px] max-w-[120px] w-fit object-cover shadow-lg"
                          />
                          <div className="border-1 border-solid border-secondary-yellow bg-primary-white  absolute top-1 left-0 w-14 h-14 rounded-full flex flex-col justify-center items-center">
                            <p className="text-tertiary-dark -mb-1 font-semibold text-montserrat text-p1">
                              {book.validity}yrs
                            </p>
                            <p className="text-xs font-semibold text-secondary-dark">
                              validity
                            </p>
                          </div>
                        </div>
                        <div>
                          <h4 className="whitespace-nowrap mb-3 overflow-hidden text-ellipsis text-left font-medium text-p1 whitespace-wrap text-[#0c0b0c] ">
                            {book.title}
                          </h4>
                          <p className="text-secondary-dark whitespace-nowrap w-[90%] overflow-hidden text-ellipsis capitalize text-mobile-p1 sm:text-tablet-p1 lg:text-p1 font-semibold">
                            {book.Bauthors?.map((auther, index, arr) => (
                              <span>
                                {arr.indexOf(arr[index]) === arr.length - 1
                                  ? auther.bookAuthorName
                                  : `${auther.bookAuthorName}, `}
                              </span>
                            ))}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              )}
              {sortSet === "Sets" && (
                <div className="gap-3 grid grid-cols-[repeat(2,50%)] my-[18px]">
                  {sets
                    ?.filter((value) => {
                      if (setSearch === "") {
                        return value;
                      } else if (
                        value.name
                          .toLowerCase()
                          .includes(setSearch.toLowerCase())
                      ) {
                        return value;
                      }
                    })
                    .map((book) => (
                      <div
                        key={book.id}
                        onDragStart={(e) => handleDragStart(e, book, 4)}
                        draggable={!disabled.bookAssignment}
                        className={`${filterset(book) && "cursor-not-allowed bg-[#c2c2bd57]"
                          } flex flex-col justify-between leading-[19px] w-[160px] h-[197px] p-2 shadow-[4px_4px_4px_rgba(224,224,224,0.25)] rounded-[2px]`}
                      >
                        <div className="h-[108px] flex justify-center items-center bg-neutral-10 relative">
                          <img
                            src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/setCovers/${book.cover}`}
                            alt="front cover h-full "
                            className="h-[88px] max-w-[120px] w-fit object-cover shadow-lg"
                          />
                          <div className="border-1 border-solid border-secondary-yellow bg-primary-white  absolute top-1 left-0 w-14 h-14 rounded-full flex flex-col justify-center items-center">
                            <p className="text-tertiary-dark -mb-1 font-semibold text-montserrat text-p1">
                              {book.validity}yrs
                            </p>
                            <p className="text-xs font-semibold text-secondary-dark">
                              validity
                            </p>
                          </div>
                        </div>
                        <div>
                          <h4 className="whitespace-nowrap mb-3 overflow-hidden text-ellipsis text-left font-medium text-p1 whitespace-wrap text-[#0c0b0c] ">
                            {book.title}
                          </h4>
                          {/* <p className="whitespace-nowrap overflow-hidden text-ellipsis text-secondary-dark capitalize font-semibold text-[15px]">
                      {book.Bauthors.map((elm, index) => elm.bookAuthorName)}
                    </p> */}
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
            <div
              className={`${disabled.bookAssignment && "opacity-[0.5]"
                } py-[26px] px-[18px] min-w-[290px] w-[326px] bg-[rgba(255,255,255,0.9)] shadow-[0px_5px_10px_rgba(32,29,31,0.11)] rounded-[5px]`}
            >
              <div className="flex justify-between items-center">
                <h2 className="text-montserrat font-semibold text-h3 text-heading ">
                  Users
                </h2>
                <div className="flex items-center">
                  <label className="text-[14px] font-semibold text-secondary-light mr-2">
                    Filter by
                  </label>

                  <button
                    onClick={(e) => {
                      setDropdowns((prevState) => ({
                        ...prevState,
                        users: !dropdowns.users,
                      }))
                      e.stopPropagation();
                    }}
                    className="relative flex px-2 justify-between items-center w-[110px] h-[29px] bg-neutral-10 text-secondary-dark font-semibold text-[15px]"
                  >
                    {sortBy}
                    <span className="ml-[3px]">
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="18" height="15" rx="2" fill="#FFD600" />
                        <path d="M9.5 11L5 5H14L9.5 11Z" fill="#7A7976" />
                      </svg>
                    </span>
                    {dropdowns.users && (
                      <div
                        onClick={(e) => {
                          setDropdowns((prevState) => ({
                            ...prevState,
                            users: false,
                          }));
                          e.stopPropagation();
                        }}
                        className="absolute top-[31px] left-0 w-full h-fit bg-primary-white border-[rgba(201,201,201,0.37)] border-solid border-[0.5px]"
                      >
                        <ReactTooltip
                          place="right"
                          // backgroundColor="#737272a"
                          type="success"
                          effect="solid"
                          offset={{ bottom: 8 }}
                          delayShow="150"
                        />
                        <button
                          onClick={() => {
                            !(depInCart?.length > 0) && setSortBy("Individual");
                          }}
                          className={`w-full font-medium text-[14px] text-secondary-light ${depInCart?.length > 0
                              ? "bg-[#f0f0f0] cursor-not-allowed text-[#aeaeb669]"
                              : ""
                            } hover:bg-[#f0f0f0] h-[33.33%] text-left pl-[10px]`}
                          data-tip={`${depInCart?.length > 0
                              ? "You can select one at time"
                              : ""
                            }`}
                        >
                          Individual
                        </button>
                        <button
                          onClick={() => {
                            !(usersInCart?.length > 0) &&
                              setSortBy("Department");
                          }}
                          className={`w-full font-medium text-[14px] text-secondary-light ${usersInCart?.length > 0
                              ? "bg-[#f0f0f0] cursor-not-allowed text-[#aeaeb669]"
                              : ""
                            } hover:bg-[#f0f0f0] h-[33.33%] text-left pl-[10px]`}
                          data-tip={`${usersInCart?.length > 0
                              ? "You can select one at time"
                              : ""
                            }`}
                        >
                          Department
                        </button>
                        {/* <button
                      onClick={() => {
                        setSortBy("Groups");
                      }}
                      className="w-full font-medium text-[14px] text-secondary-light hover:bg-[#f0f0f0] h-[33.33%] text-left pl-[10px]"
                    >
                      Groups
                    </button> */}
                      </div>
                    )}
                  </button>
                </div>
              </div>
              <form className="my-[21px] flex justify-between items-center border-1 border-solid border-[#eee] w-full h-[48px]">
                <button
                  disabled={disabled.bookAssignment}
                  className="py-4 pl-4 pr-2"
                >
                  <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 17.5L13.1333 13.6333M15.2222 8.61111C15.2222 12.5385 12.0385 15.7222 8.11111 15.7222C4.18375 15.7222 1 12.5385 1 8.61111C1 4.68375 4.18375 1.5 8.11111 1.5C12.0385 1.5 15.2222 4.68375 15.2222 8.61111Z"
                      stroke="#702963"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                {sortBy === "Individual" && (
                  <input
                    disabled={disabled.bookAssignment}
                    className=" pr-[18px] outline-0 border-0 w-[calc(100%-24px)] h-full font-semibold text-heading  "
                    placeholder="Search"
                    value={usersSearch}
                    onChange={(e) => {
                      setUsersSearch(e.target.value);
                    }}
                  />
                )}
                {sortBy === "Department" && (
                  <input
                    disabled={disabled.bookAssignment}
                    className=" pr-[18px] outline-0 border-0 w-[calc(100%-24px)] h-full font-semibold text-heading  "
                    placeholder="Search"
                    value={depSearch}
                    onChange={(e) => {
                      setDepSearch(e.target.value);
                    }}
                  />
                )}
              </form>

              {sortBy === "Individual" &&
                users
                  ?.filter((value) => {
                    if (usersSearch === "") {
                      return value;
                    } else if (
                      value.fullName
                        .toLowerCase()
                        .includes(usersSearch.toLowerCase())
                    ) {
                      return value;
                    }
                  })
                  .map((user) => (
                    <div
                      onDragStart={(e) => {
                        depInCart.length <= 0 &&
                          handleDragStartUser(e, user, 2);
                      }}
                      draggable={!disabled.bookAssignment}
                      key={user.id}
                      className={`${filterUser(user.id) &&
                        "cursor-not-allowed text-[#938d8d54] bg-[#dfd2d259] "
                        } mb-4 flex items-center border-[0.5px] border-solid border-primary-dark-20 h-[46px] py-[5px] px-3`}
                    >
                      <div className="bg-neutral-10 flex justify-center items-center rounded-full w-[37px] h-[35px]">
                        {user.photo === null ? (
                          <p className="uppercase text-[23px] text-[#938d8d85] font-bold">
                            {user.fullName[0]}
                          </p>
                        ) : (
                          <img
                            className="w-full object-cover"
                            src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${user.photo}`}
                            alt="img"
                          />
                        )}
                      </div>
                      <p className="text-heading-false capitalize font-bold text-h5 ml-3">
                        {user.fullName}
                      </p>
                    </div>
                  ))}
              {sortBy === "Department" &&
                department
                  ?.filter((value) => {
                    if (depSearch === "") {
                      return value;
                    } else if (
                      value.departmentName
                        .toLowerCase()
                        .includes(depSearch.toLowerCase())
                    ) {
                      return value;
                    }
                  })
                  .map((dep) => (
                    <div
                      onDragStart={(e) => {
                        usersInCart.length <= 0 &&
                          handleDragStartDep(e, dep, 3);
                      }}
                      draggable={!disabled.bookAssignment}
                      key={dep.id}
                      className={`${filterDep(dep.id) &&
                        "cursor-not-allowed text-[#938d8d54] bg-[#dfd2d259]"
                        } mb-4 flex items-center border-[0.5px] border-solid border-primary-dark-20 h-[46px] py-[5px] `}
                    >
                      <p className="text-heading-false capitalize font-bold whitespace-nowrap overflow-hidden text-ellipsis text-[18px] ml-3">
                        {dep.departmentName}
                      </p>
                    </div>
                  ))}
            </div>
            <div className="py-[26px] px-[18px] w-[471px] bg-[rgba(255,255,255,0.9)] shadow-[0px_5px_10px_rgba(32,29,31,0.11)] rounded-[5px]">
              <div className="flex mb-8 justify-between items-center">
                <h2 className="text-montserrat font-semibold text-h3 text-heading ">
                  Assignment Area
                </h2>
                {disabled.bookAssignment ? (
                  <button
                    onClick={() =>
                      setDisabled((prevState) => ({
                        ...prevState,
                        bookAssignment: false,
                      }))
                    }
                    className="px-[41px] py-2 border-[0.5px] text-heading text-montserrat font-semibold  border-solid border-[#cacaca]"
                  >
                    Edit
                  </button>
                ) : (
                  <button
                    disabled={
                      (sortSet === "Books" &&
                        booksInCart?.bookData.length === 0) ||
                      (sortSet === "Sets" &&
                        setsInCart?.setData?.length == 0) ||
                      (sortBy === "Individual" && usersInCart?.length === 0) ||
                      (sortBy === "Department" && depInCart?.length === 0)
                    }
                    onClick={() =>
                      setDisabled((prevState) => ({
                        ...prevState,
                        bookAssignment: true,
                      }))
                    }
                    className={`${(booksInCart?.bookData.length === 0 ||
                        (sortBy === "Individual" &&
                          usersInCart?.length === 0) ||
                        (sortBy === "Department" && depInCart?.length === 0)) &&
                      "opacity-[0.5]"
                      } px-[41px] py-2 border-[0.5px] text-[#ffff] text-montserrat font-semibold  border-solid bg-primary-dark border-[#cacaca]`}
                  >
                    Proceed
                  </button> 
                )}
              </div>
              {disabled.dragDrop ? (
                <div className="flex flex-col justify-center items-center bg-[#f8f8f8] h-[calc(100%-63px)] shadow-[4px_5px_1px_rgba(173,173,173,0.1)]">
                  <p className="font-semibold text-secondary-light text-montserrat text-center">
                    Assign books from Library
                  </p>
                  <div className="mt-[35px] w-[268px] h-[84px] border-1 border-dashed border-[#bebebe] flex justify-center items-center">
                    <p className="text-secondary-dark font-semibold text-[h5] text-center">
                      Drag &#38; Drop <br />
                      Users/ Departments and Books/ Sets <br />
                      for Assignment
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.dataTransfer.dropEffect = "move";
                  }}
                  onDrop={handleDrop}
                  className={`px-2 relative flex flex-col ${setsInCart?.setData?.length == 0 &&
                      booksInCart?.bookData.length === 0 &&
                      usersInCart?.length === 0
                      ? "justify-center items-center"
                      : "justify-start items-start"
                    }  ${disabled.bookAssignment ? "bg-[#f8f8f8]" : "bg-[#F3EBF2]"
                    } h-[calc(100%-63px)] shadow-[4px_5px_1px_rgba(173,173,173,0.1)]`}
                >
                  <div className=" w-full h-[1px] top-0 left-0 absolute bg-[linear-gradient(to_right,_transparent_50%,_#C5A7C0_50%)] bg-[length:15px_100px]"></div>
                  <div className=" w-full h-[1px] bottom-0 left-0 absolute bg-[linear-gradient(to_right,_transparent_50%,_#C5A7C0_50%)] bg-[length:15px_100px]"></div>
                  <div className=" w-[1px] h-full top-0 left-0 absolute bg-[linear-gradient(to_bottom,_transparent_50%,_#C5A7C0_50%)] bg-[length:15px_15px]"></div>
                  <div className=" w-[1px] h-full top-0 -right-0 absolute bg-[linear-gradient(to_bottom,_transparent_50%,_#C5A7C0_50%)] bg-[length:15px_15px]"></div>
                  {booksInCart?.bookData.length === 0 &&
                    usersInCart?.length === 0 &&
                    depInCart?.length === 0 &&
                    setsInCart?.setData?.length == 0 ? (
                    <div className="relative w-[268px] h-[84px] flex justify-center items-center">
                      <div className=" w-full h-[1px] top-0 left-0 absolute bg-[linear-gradient(to_right,_transparent_50%,_#C5A7C0_50%)] bg-[length:15px_100px]"></div>
                      <div className=" w-full h-[1px] bottom-0 left-0 absolute bg-[linear-gradient(to_right,_transparent_50%,_#C5A7C0_50%)] bg-[length:15px_100px]"></div>
                      <p className="text-secondary-dark font-semibold text-[h5] text-center">
                        Drag &#38; Drop <br />
                        Users/ Departments and Books/ Sets <br />
                        for Assignment
                      </p>
                      <div className=" w-[1px] h-full top-0 left-0 absolute bg-[linear-gradient(to_bottom,_transparent_50%,_#C5A7C0_50%)] bg-[length:15px_15px]"></div>
                      <div className=" w-[1px] h-full top-0 -right-0 absolute bg-[linear-gradient(to_bottom,_transparent_50%,_#C5A7C0_50%)] bg-[length:15px_15px]"></div>
                    </div>
                  ) : (
                    <>
                      {booksInCart?.bookData.length > 0 && (
                        <div className="w-full justify-between bg-primary-white py-2 gap-[2px] grid grid-cols-[repeat(2,50%)] my-[18px] p-2">
                          {bookData?.map((book) => (
                            <div
                              key={book.parentId}
                              className="relative bg-primary-white flex flex-col justify-between leading-[19px] min-w-[160px] h-[197px] p-2 border-1 border-[#e9e9e9] shadow-[4px_4px_4px_rgba(224,224,224,0.25)] rounded-[2px]"
                            >
                              <div
                                style={{ background: book?.backgroundColor }}
                                className="h-[108px] flex justify-center items-center bg-neutral-10 relative"
                              >
                                <img
                                  src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${book.imageCoverFront}`}
                                  alt="front cover h-full "
                                  className="h-[88px] max-w-[120px] w-fit object-cover shadow-lg"
                                />
                                <div className="border-1 border-solid border-secondary-yellow bg-primary-white  absolute top-1 left-0 w-14 h-14 rounded-full flex flex-col justify-center items-center">
                                  <p className="text-tertiary-dark -mb-1 font-semibold text-montserrat text-p1">
                                    {book.validity}yrs
                                  </p>
                                  <p className="text-xs font-semibold text-secondary-dark">
                                    validity
                                  </p>
                                </div>
                              </div>
                              <div>
                                <h4 className="whitespace-nowrap overflow-hidden text-ellipsis text-left font-medium text-p1 whitespace-wrap text-[#0c0b0c] ">
                                  {book.title}
                                </h4>
                                <p className="text-secondary-dark whitespace-nowrap w-[90%] overflow-hidden text-ellipsis capitalize text-mobile-p1 sm:text-tablet-p1 lg:text-p1 font-semibold">
                                  {book.Bauthors?.map((auther, index, arr) => (
                                    <span>
                                      {arr.indexOf(arr[index]) ===
                                        arr.length - 1
                                        ? auther.bookAuthorName
                                        : `${auther.bookAuthorName}, `}
                                    </span>
                                  ))}
                                </p>
                              </div>
                              <button
                                onClick={() => deleteBookfromcart(book.id)}
                                className="absolute right-3 top-5"
                              >
                                <svg
                                  width="8"
                                  height="7"
                                  viewBox="0 0 8 7"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.4787 6.65125C7.29867 6.79752 7.0545 6.87969 6.7999 6.87969C6.54531 6.87969 6.30114 6.79752 6.1211 6.65125L4.0003 4.68175L1.8795 6.6506C1.79063 6.724 1.68474 6.78237 1.56796 6.82236C1.45118 6.86234 1.32581 6.88314 1.19908 6.88356C1.07236 6.88398 0.946784 6.86401 0.829605 6.8248C0.712427 6.78559 0.605962 6.72792 0.516352 6.65511C0.426743 6.5823 0.355762 6.4958 0.307505 6.40059C0.259247 6.30539 0.234667 6.20336 0.235183 6.10039C0.235699 5.99743 0.2613 5.89556 0.310511 5.80068C0.359721 5.70579 0.431567 5.61976 0.521903 5.54755L2.7283 3.50005L0.521103 1.45125C0.430767 1.37904 0.358921 1.29301 0.309711 1.19812C0.260501 1.10324 0.234899 1.00138 0.234383 0.89841C0.233867 0.795446 0.258447 0.693417 0.306705 0.598209C0.354962 0.503001 0.425943 0.416499 0.515552 0.343691C0.605162 0.270883 0.711627 0.213212 0.828805 0.174002C0.945984 0.134793 1.07156 0.114822 1.19828 0.115241C1.32501 0.11566 1.45038 0.136461 1.56716 0.176445C1.68394 0.216428 1.78983 0.274803 1.8787 0.348201L4.0003 2.31835L6.1211 0.348201C6.20998 0.274803 6.31586 0.216428 6.43264 0.176445C6.54943 0.136461 6.6748 0.11566 6.80152 0.115241C6.92825 0.114822 7.05382 0.134793 7.171 0.174002C7.28818 0.213212 7.39464 0.270883 7.48425 0.343691C7.57386 0.416499 7.64484 0.503001 7.6931 0.598209C7.74136 0.693417 7.76594 0.795446 7.76542 0.89841C7.76491 1.00138 7.7393 1.10324 7.69009 1.19812C7.64088 1.29301 7.56904 1.37904 7.4787 1.45125L5.2723 3.50005L7.4787 5.54755C7.56796 5.61999 7.63877 5.70602 7.68708 5.80071C7.73539 5.8954 7.76026 5.9969 7.76026 6.0994C7.76026 6.2019 7.73539 6.3034 7.68708 6.39809C7.63877 6.49278 7.56796 6.57881 7.4787 6.65125Z"
                                    fill="black"
                                    fill-opacity="0.53"
                                  />
                                </svg>
                              </button>
                            </div>
                          ))}
                        </div>
                      )}

                      {setsInCart?.setData?.length > 0 && (
                        <div className="w-full justify-between bg-primary-white py-2 gap-[2px] grid grid-cols-[repeat(2,50%)] my-[18px] p-2">
                          {setsData?.map((book) => (
                            <div
                              key={book.parentId}
                              className="relative bg-primary-white flex flex-col justify-between leading-[19px] min-w-[160px] h-[197px] p-2 border-1 border-[#e9e9e9] shadow-[4px_4px_4px_rgba(224,224,224,0.25)] rounded-[2px]"
                            >
                              <div className="h-[108px] flex justify-center items-center bg-neutral-10 relative">
                                <img
                                  src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/setCovers/${book.cover}`}
                                  alt="front cover h-full "
                                  className="h-[88px] max-w-[120px] w-fit object-cover shadow-lg"
                                />
                                <div className="border-1 border-solid border-secondary-yellow bg-primary-white  absolute top-1 left-0 w-14 h-14 rounded-full flex flex-col justify-center items-center">
                                  <p className="text-tertiary-dark -mb-1 font-semibold text-montserrat text-p1">
                                    {book.validity}yrs
                                  </p>
                                  <p className="text-xs font-semibold text-secondary-dark">
                                    validity
                                  </p>
                                </div>
                              </div>
                              <div>
                                <h4 className="whitespace-nowrap overflow-hidden text-ellipsis text-left font-medium text-p1 whitespace-wrap text-[#0c0b0c] ">
                                  {book.title}
                                </h4>
                                {/* <p className="whitespace-nowrap overflow-hidden text-ellipsis text-secondary-dark capitalize font-semibold text-[15px]">
                              {book.Bauthors.map((elm) => elm.bookAuthorName)}
                            </p> */}
                              </div>
                              <button
                                onClick={() => deleteSetfromcart(book.id)}
                                className="absolute right-3 top-5"
                              >
                                <svg
                                  width="8"
                                  height="7"
                                  viewBox="0 0 8 7"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.4787 6.65125C7.29867 6.79752 7.0545 6.87969 6.7999 6.87969C6.54531 6.87969 6.30114 6.79752 6.1211 6.65125L4.0003 4.68175L1.8795 6.6506C1.79063 6.724 1.68474 6.78237 1.56796 6.82236C1.45118 6.86234 1.32581 6.88314 1.19908 6.88356C1.07236 6.88398 0.946784 6.86401 0.829605 6.8248C0.712427 6.78559 0.605962 6.72792 0.516352 6.65511C0.426743 6.5823 0.355762 6.4958 0.307505 6.40059C0.259247 6.30539 0.234667 6.20336 0.235183 6.10039C0.235699 5.99743 0.2613 5.89556 0.310511 5.80068C0.359721 5.70579 0.431567 5.61976 0.521903 5.54755L2.7283 3.50005L0.521103 1.45125C0.430767 1.37904 0.358921 1.29301 0.309711 1.19812C0.260501 1.10324 0.234899 1.00138 0.234383 0.89841C0.233867 0.795446 0.258447 0.693417 0.306705 0.598209C0.354962 0.503001 0.425943 0.416499 0.515552 0.343691C0.605162 0.270883 0.711627 0.213212 0.828805 0.174002C0.945984 0.134793 1.07156 0.114822 1.19828 0.115241C1.32501 0.11566 1.45038 0.136461 1.56716 0.176445C1.68394 0.216428 1.78983 0.274803 1.8787 0.348201L4.0003 2.31835L6.1211 0.348201C6.20998 0.274803 6.31586 0.216428 6.43264 0.176445C6.54943 0.136461 6.6748 0.11566 6.80152 0.115241C6.92825 0.114822 7.05382 0.134793 7.171 0.174002C7.28818 0.213212 7.39464 0.270883 7.48425 0.343691C7.57386 0.416499 7.64484 0.503001 7.6931 0.598209C7.74136 0.693417 7.76594 0.795446 7.76542 0.89841C7.76491 1.00138 7.7393 1.10324 7.69009 1.19812C7.64088 1.29301 7.56904 1.37904 7.4787 1.45125L5.2723 3.50005L7.4787 5.54755C7.56796 5.61999 7.63877 5.70602 7.68708 5.80071C7.73539 5.8954 7.76026 5.9969 7.76026 6.0994C7.76026 6.2019 7.73539 6.3034 7.68708 6.39809C7.63877 6.49278 7.56796 6.57881 7.4787 6.65125Z"
                                    fill="black"
                                    fill-opacity="0.53"
                                  />
                                </svg>
                              </button>
                            </div>
                          ))}
                        </div>
                      )}

                      {usersInCart?.length > 0 && (
                        <div className="mt-2 w-full gap-[10px] flex justify-between flex-wrap">
                          {usersInCart?.map((user) => (
                            <div className="flex items-center relative w-[176px] h-12 bg-primary-white border-[0.5px] border-solid border-[rgba(169,168,169,0.35)] shadow-[3px_4px_3px_rgba(148,145,145,0.13)] p-[7px]">
                              <div className="bg-neutral-10 flex justify-center items-center rounded-full w-[37px] h-[35px]">
                                {user.Ouser.photo === null ? (
                                  <p className="uppercase text-[23px] text-[#938d8d85] font-bold">
                                    {user.Ouser.fullName[0]}
                                  </p>
                                ) : (
                                  <img
                                    className="w-full object-cover"
                                    src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${user.photo}`}
                                    alt="img"
                                  />
                                )}
                              </div>{" "}
                              <p className="text-heading-false capitalize font-bold text-tablet-p1 ml-3">
                                {user.Ouser.fullName}
                              </p>
                              <button
                                onClick={() => deleteUserfromcart(user.id)}
                                className="absolute right-3 top-5"
                              >
                                <svg
                                  width="8"
                                  height="7"
                                  viewBox="0 0 8 7"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.4787 6.65125C7.29867 6.79752 7.0545 6.87969 6.7999 6.87969C6.54531 6.87969 6.30114 6.79752 6.1211 6.65125L4.0003 4.68175L1.8795 6.6506C1.79063 6.724 1.68474 6.78237 1.56796 6.82236C1.45118 6.86234 1.32581 6.88314 1.19908 6.88356C1.07236 6.88398 0.946784 6.86401 0.829605 6.8248C0.712427 6.78559 0.605962 6.72792 0.516352 6.65511C0.426743 6.5823 0.355762 6.4958 0.307505 6.40059C0.259247 6.30539 0.234667 6.20336 0.235183 6.10039C0.235699 5.99743 0.2613 5.89556 0.310511 5.80068C0.359721 5.70579 0.431567 5.61976 0.521903 5.54755L2.7283 3.50005L0.521103 1.45125C0.430767 1.37904 0.358921 1.29301 0.309711 1.19812C0.260501 1.10324 0.234899 1.00138 0.234383 0.89841C0.233867 0.795446 0.258447 0.693417 0.306705 0.598209C0.354962 0.503001 0.425943 0.416499 0.515552 0.343691C0.605162 0.270883 0.711627 0.213212 0.828805 0.174002C0.945984 0.134793 1.07156 0.114822 1.19828 0.115241C1.32501 0.11566 1.45038 0.136461 1.56716 0.176445C1.68394 0.216428 1.78983 0.274803 1.8787 0.348201L4.0003 2.31835L6.1211 0.348201C6.20998 0.274803 6.31586 0.216428 6.43264 0.176445C6.54943 0.136461 6.6748 0.11566 6.80152 0.115241C6.92825 0.114822 7.05382 0.134793 7.171 0.174002C7.28818 0.213212 7.39464 0.270883 7.48425 0.343691C7.57386 0.416499 7.64484 0.503001 7.6931 0.598209C7.74136 0.693417 7.76594 0.795446 7.76542 0.89841C7.76491 1.00138 7.7393 1.10324 7.69009 1.19812C7.64088 1.29301 7.56904 1.37904 7.4787 1.45125L5.2723 3.50005L7.4787 5.54755C7.56796 5.61999 7.63877 5.70602 7.68708 5.80071C7.73539 5.8954 7.76026 5.9969 7.76026 6.0994C7.76026 6.2019 7.73539 6.3034 7.68708 6.39809C7.63877 6.49278 7.56796 6.57881 7.4787 6.65125Z"
                                    fill="black"
                                    fill-opacity="0.53"
                                  />
                                </svg>
                              </button>
                            </div>
                          ))}
                        </div>
                      )}

                      {depInCart?.length > 0 && (
                        <div className="mt-6 w-full gap-[10px] flex justify-between flex-wrap">
                          {depInCart?.map((dep) => (
                            <div className="flex items-center relative w-[176px] h-12 bg-primary-white border-[0.5px] border-solid border-[rgba(169,168,169,0.35)] shadow-[3px_4px_3px_rgba(148,145,145,0.13)] p-[7px]">
                              <p className="capitalize text-heading-false font-bold text-tablet-p1 ml-3">
                                {dep.Department.departmentName}
                              </p>
                              <button
                                onClick={() => deleteDepfromcart(dep.id)}
                                className="absolute right-3 top-5"
                              >
                                <svg
                                  width="8"
                                  height="7"
                                  viewBox="0 0 8 7"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.4787 6.65125C7.29867 6.79752 7.0545 6.87969 6.7999 6.87969C6.54531 6.87969 6.30114 6.79752 6.1211 6.65125L4.0003 4.68175L1.8795 6.6506C1.79063 6.724 1.68474 6.78237 1.56796 6.82236C1.45118 6.86234 1.32581 6.88314 1.19908 6.88356C1.07236 6.88398 0.946784 6.86401 0.829605 6.8248C0.712427 6.78559 0.605962 6.72792 0.516352 6.65511C0.426743 6.5823 0.355762 6.4958 0.307505 6.40059C0.259247 6.30539 0.234667 6.20336 0.235183 6.10039C0.235699 5.99743 0.2613 5.89556 0.310511 5.80068C0.359721 5.70579 0.431567 5.61976 0.521903 5.54755L2.7283 3.50005L0.521103 1.45125C0.430767 1.37904 0.358921 1.29301 0.309711 1.19812C0.260501 1.10324 0.234899 1.00138 0.234383 0.89841C0.233867 0.795446 0.258447 0.693417 0.306705 0.598209C0.354962 0.503001 0.425943 0.416499 0.515552 0.343691C0.605162 0.270883 0.711627 0.213212 0.828805 0.174002C0.945984 0.134793 1.07156 0.114822 1.19828 0.115241C1.32501 0.11566 1.45038 0.136461 1.56716 0.176445C1.68394 0.216428 1.78983 0.274803 1.8787 0.348201L4.0003 2.31835L6.1211 0.348201C6.20998 0.274803 6.31586 0.216428 6.43264 0.176445C6.54943 0.136461 6.6748 0.11566 6.80152 0.115241C6.92825 0.114822 7.05382 0.134793 7.171 0.174002C7.28818 0.213212 7.39464 0.270883 7.48425 0.343691C7.57386 0.416499 7.64484 0.503001 7.6931 0.598209C7.74136 0.693417 7.76594 0.795446 7.76542 0.89841C7.76491 1.00138 7.7393 1.10324 7.69009 1.19812C7.64088 1.29301 7.56904 1.37904 7.4787 1.45125L5.2723 3.50005L7.4787 5.54755C7.56796 5.61999 7.63877 5.70602 7.68708 5.80071C7.73539 5.8954 7.76026 5.9969 7.76026 6.0994C7.76026 6.2019 7.73539 6.3034 7.68708 6.39809C7.63877 6.49278 7.56796 6.57881 7.4787 6.65125Z"
                                    fill="black"
                                    fill-opacity="0.53"
                                  />
                                </svg>
                              </button>
                            </div>
                          ))}
                        </div>
                      )}

                      {disabled.bookAssignment && (
                        <button
                          onClick={() =>
                            setModals((prevState) => ({
                              ...prevState,
                              summary: true,
                            }))
                          }
                          className="mt-6 w-full h-14 bg-primary-white text-heading font-bold"
                        >
                          Assign
                        </button>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>

          {modals.summary && (
            <div className="bg-primary-light w-full h-full absolute top-0 left-0 p-12 flex justify-center items-start">
              <div className="bg-primary-white flex flex-col relative w-full lg:w-pop-up  p-8">
                <h4 className="text-h4 text-tertiary-dark font-bold mb-[44px]">
                  Assignment Summary
                </h4>
                {/* <h5 className="text-heading font-bold mb-[14px] ">
              Book assignment details
            </h5> */}
                <p className="text-secondary-light mb-[14px]">
                  The following Books/ Sets will be assigned to the following
                  Users/ Departments
                </p>
                {booksInCart?.bookData.length > 0 && (
                  <div className="w-10/12 justify-between bg-primary-white py-2 gap-4 grid grid-cols-[repeat(2,50%)] my-[18px]">
                    {bookData?.map((book) => (
                      <div
                        key={book.id}
                        className="relative bg-primary-white flex flex-col justify-between leading-[19px] min-w-[160px] h-[197px] py-2 px-[14px] shadow-[4px_4px_4px_rgba(224,224,224,0.25)] rounded-[2px]"
                      >
                        <div
                          style={{ background: book?.backgroundColor }}
                          className="h-[108px] flex justify-center items-center bg-neutral-10 relative"
                        >
                          <img
                            src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${book.imageCoverFront}`}
                            alt="front cover h-full "
                            className="h-[88px] w-fit object-cover shadow-lg"
                          />
                          <div className="border-1 border-solid border-secondary-yellow bg-primary-white  absolute top-1 left-0 w-14 h-14 rounded-full flex flex-col justify-center items-center">
                            <p className="text-tertiary-dark -mb-1 font-semibold text-montserrat text-p1">
                              {book.validity}yrs
                            </p>
                            <p className="text-xs font-semibold text-secondary-dark">
                              validity
                            </p>
                          </div>
                        </div>
                        <div>
                          <h4 className="whitespace-nowrap overflow-hidden text-ellipsis text-left font-medium text-p1 whitespace-wrap text-[#0c0b0c] ">
                            {book.title}
                          </h4>
                          <p className="text-secondary-dark whitespace-nowrap w-[90%] overflow-hidden text-ellipsis capitalize text-mobile-p1 sm:text-tablet-p1 lg:text-p1 font-semibold">
                            {book.Bauthors?.map((auther, index, arr) => (
                              <span>
                                {arr.indexOf(arr[index]) === arr.length - 1
                                  ? auther.bookAuthorName
                                  : `${auther.bookAuthorName}, `}
                              </span>
                            ))}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {setsInCart?.setData?.length > 0 && (
                  <div className="w-full justify-between bg-primary-white py-2 gap-[2px] grid grid-cols-[repeat(2,50%)] my-[18px] p-2">
                    {setsData?.map((book) => (
                      <div
                        key={book.parentId}
                        className="relative bg-primary-white flex flex-col justify-between leading-[19px] min-w-[160px] h-[197px] p-2 border-1 border-[#e9e9e9] shadow-[4px_4px_4px_rgba(224,224,224,0.25)] rounded-[2px]"
                      >
                        <div className="h-[108px] flex justify-center items-center bg-neutral-10 relative">
                          <img
                            src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/setCovers/${book.cover}`}
                            alt="front cover h-full "
                            className="h-[88px] max-w-[120px] w-fit object-cover shadow-lg"
                          />
                          <div className="border-1 border-solid border-secondary-yellow bg-primary-white  absolute top-1 left-0 w-14 h-14 rounded-full flex flex-col justify-center items-center">
                            <p className="text-tertiary-dark -mb-1 font-semibold text-montserrat text-p1">
                              {book.validity}yrs
                            </p>
                            <p className="text-xs font-semibold text-secondary-dark">
                              validity
                            </p>
                          </div>
                        </div>
                        <div>
                          <h4 className="whitespace-nowrap overflow-hidden text-ellipsis text-left font-medium text-p1 whitespace-wrap text-[#0c0b0c] ">
                            {book.title}
                          </h4>
                          {/* <p className="whitespace-nowrap overflow-hidden text-ellipsis text-secondary-dark capitalize font-semibold text-[15px]">
                              {book.Bauthors.map((elm) => elm.bookAuthorName)}
                            </p> */}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {usersInCart?.length > 0 && (
                  <div className="mt-2 w-[88%] mb-3 flex justify-between flex-wrap">
                    {usersInCart?.map((user) => (
                      <div className="flex items-center relative w-[176px] h-12 bg-primary-white border-[0.5px] border-solid border-[rgba(169,168,169,0.35)] shadow-[3px_4px_3px_rgba(148,145,145,0.13)] p-[7px]">
                        <div className="bg-neutral-10 flex justify-center items-center rounded-full w-[37px] h-[35px]">
                          {user.Ouser.photo === null ? (
                            <p className="uppercase text-[23px] text-[#938d8d85] font-bold">
                              {user.Ouser.fullName[0]}
                            </p>
                          ) : (
                            <img
                              className="w-full object-cover"
                              src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${user.photo}`}
                              alt="img"
                            />
                          )}
                        </div>
                        <p className="text-heading-false pr-4 whitespace-nowrap text-ellipsis overflow-hidden capitalize font-bold text-tablet-p1 ml-3">
                          {user.Ouser.fullName}
                        </p>
                        {/* <button
                            onClick={() => deleteUserfromcart(user.id)}
                            className="absolute right-3 top-5"
                          >
                            <svg
                              width="8"
                              height="7"
                              viewBox="0 0 8 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.4787 6.65125C7.29867 6.79752 7.0545 6.87969 6.7999 6.87969C6.54531 6.87969 6.30114 6.79752 6.1211 6.65125L4.0003 4.68175L1.8795 6.6506C1.79063 6.724 1.68474 6.78237 1.56796 6.82236C1.45118 6.86234 1.32581 6.88314 1.19908 6.88356C1.07236 6.88398 0.946784 6.86401 0.829605 6.8248C0.712427 6.78559 0.605962 6.72792 0.516352 6.65511C0.426743 6.5823 0.355762 6.4958 0.307505 6.40059C0.259247 6.30539 0.234667 6.20336 0.235183 6.10039C0.235699 5.99743 0.2613 5.89556 0.310511 5.80068C0.359721 5.70579 0.431567 5.61976 0.521903 5.54755L2.7283 3.50005L0.521103 1.45125C0.430767 1.37904 0.358921 1.29301 0.309711 1.19812C0.260501 1.10324 0.234899 1.00138 0.234383 0.89841C0.233867 0.795446 0.258447 0.693417 0.306705 0.598209C0.354962 0.503001 0.425943 0.416499 0.515552 0.343691C0.605162 0.270883 0.711627 0.213212 0.828805 0.174002C0.945984 0.134793 1.07156 0.114822 1.19828 0.115241C1.32501 0.11566 1.45038 0.136461 1.56716 0.176445C1.68394 0.216428 1.78983 0.274803 1.8787 0.348201L4.0003 2.31835L6.1211 0.348201C6.20998 0.274803 6.31586 0.216428 6.43264 0.176445C6.54943 0.136461 6.6748 0.11566 6.80152 0.115241C6.92825 0.114822 7.05382 0.134793 7.171 0.174002C7.28818 0.213212 7.39464 0.270883 7.48425 0.343691C7.57386 0.416499 7.64484 0.503001 7.6931 0.598209C7.74136 0.693417 7.76594 0.795446 7.76542 0.89841C7.76491 1.00138 7.7393 1.10324 7.69009 1.19812C7.64088 1.29301 7.56904 1.37904 7.4787 1.45125L5.2723 3.50005L7.4787 5.54755C7.56796 5.61999 7.63877 5.70602 7.68708 5.80071C7.73539 5.8954 7.76026 5.9969 7.76026 6.0994C7.76026 6.2019 7.73539 6.3034 7.68708 6.39809C7.63877 6.49278 7.56796 6.57881 7.4787 6.65125Z"
                                fill="black"
                                fill-opacity="0.53"
                              />
                            </svg>
                          </button> */}
                      </div>
                    ))}
                  </div>
                )}
                {depInCart?.length > 0 && (
                  <div className="mt-3 w-full gap-[10px] flex  flex-col mb-8">
                    <div className="flex mb-3">
                      <p className="w-[164px] text-16px font-[600]">
                        Departments
                      </p>
                      <p className="text-16px font-[600]">Users</p>
                    </div>
                    {depInCart?.map((dep) => (
                      <div className="flex flex-row gap-5">
                        <div className="flex items-center justify-center relative w-[172px] h-[78px] bg-primary-white border-[0.5px] border-solid border-[rgba(169,168,169,0.35)] shadow-[3px_4px_3px_rgba(148,145,145,0.13)] p-[7px]">
                          <p className="capitalize text-heading-false font-bold text-center text-tablet-p1 ml-3">
                            {dep.Department.departmentName}
                          </p>
                        </div>
                        <div className="flex items-center overflow-x-hidden z-[9] justify-center relative w-[363px] h-[78px] bg-primary-white border-[0.5px] border-solid border-[rgba(169,168,169,0.35)] shadow-[3px_4px_3px_rgba(148,145,145,0.13)] p-[7px]">
                          <button
                            onClick={() => handleLeftSlide()}
                            className="absolute left-0 top-[40%] z-40 ml-3 "
                          >
                            <svg
                              width="10"
                              height="15"
                              viewBox="0 0 10 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.075 6.55864L7.9275 0.563642C8.73375 -0.143858 10 0.431142 10 1.50489L10 13.4949C10.0002 13.7352 9.93114 13.9705 9.80107 14.1725C9.67101 14.3746 9.48545 14.5349 9.26663 14.6342C9.04781 14.7335 8.805 14.7677 8.56728 14.7325C8.32955 14.6974 8.10699 14.5945 7.92625 14.4361L1.07625 8.44114C0.941988 8.32382 0.83438 8.17912 0.76065 8.01678C0.68692 7.85443 0.648774 7.67819 0.648774 7.49989C0.648774 7.32159 0.68692 7.14535 0.76065 6.98301C0.83438 6.82066 0.941988 6.67597 1.07625 6.55864H1.075Z"
                                fill="#D9D9D9"
                              />
                            </svg>
                          </button>
                          <div
                            className={`absolute left-0 w-full flex mx-8 items-center justify-start transition-all ease-in-out delay-100`}
                            style={{ left: carousel }}
                          >
                            {dep.Department.Ousers.map((depUser) => (
                              <div className="flex justify-center items-center flex-col mr-4">
                                <div className="bg-neutral-10 flex justify-center items-center shadow rounded-full w-[37px] h-[35px]">
                                  {depUser.photo === null ? (
                                    <p className="uppercase text-[23px] text-[#938d8d85] font-bold">
                                      {depUser.fullName[0]}
                                    </p>
                                  ) : (
                                    <img
                                      className="w-full object-cover"
                                      src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${depUser.photo}`}
                                      alt="img"
                                    />
                                  )}
                                </div>
                                <p className="text-heading-false whitespace-nowrap overflow-hidden text-ellipsis capitalize text-[13px] font-[600] text-[rgba(92, 87, 87, 0.82)]">
                                  {depUser.fullName}
                                </p>
                              </div>
                            ))}
                          </div>
                          <button
                            onClick={() => handleRightSlide()}
                            className="absolute right-0 top-[40%] z-40 mr-3 "
                          >
                            <svg
                              width="10"
                              height="15"
                              viewBox="0 0 10 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.925 8.44136L2.0725 14.4364C1.26625 15.1439 4.59632e-07 14.5689 4.59632e-07 13.4951L4.59632e-07 1.50511C-0.000205574 1.2648 0.0688598 1.02953 0.198926 0.827468C0.328993 0.625405 0.514546 0.465117 0.733366 0.365797C0.952187 0.266477 1.195 0.232336 1.43272 0.267464C1.67045 0.30259 1.89301 0.405497 2.07375 0.563858L8.92375 6.55886C9.05801 6.67618 9.16562 6.82088 9.23935 6.98322C9.31308 7.14557 9.35123 7.32181 9.35123 7.50011C9.35123 7.67841 9.31308 7.85465 9.23935 8.01699C9.16562 8.17934 9.05801 8.32403 8.92375 8.44136H8.925Z"
                                fill="#D9D9D9"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div className="text-center lg:text-right">
                  <button
                    onClick={() =>
                      setModals((prevState) => ({
                        ...prevState,
                        summary: false,
                        // assignmentSuccessful: true,
                      }))
                    }
                    className="w-full mr-4 max-w-[372px] lg:w-[150px] h-[56px] bg-[#fff] text-[#000] border-solid border-[0.5px] border-[#cacaca] text-mobile-h5 lg:text-h5"
                  >
                    Cancel
                  </button>
                  {booksInCart?.bookData.length > 0 && (
                    <button
                      onClick={generateOrderId}
                      className="w-full max-w-[372px] lg:w-[150px] h-[56px] bg-primary-dark text-primary-white text-mobile-h5 lg:text-h5"
                    >
                      Confirm
                    </button>
                  )}
                  {setsInCart?.setData?.length > 0 && (
                    <button
                      onClick={generateOrderIdforSets}
                      className="w-full max-w-[372px] lg:w-[150px] h-[56px] bg-primary-dark text-primary-white text-mobile-h5 lg:text-h5"
                    >
                      Confirm
                    </button>
                  )}
                </div>

                <button
                  onClick={() =>
                    setModals((prevState) => ({ ...prevState, summary: false }))
                  }
                  className="absolute top-8 right-8"
                >
                  <svg
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2176 12.8188C10.9475 13.0888 10.5813 13.2405 10.1994 13.2405C9.81747 13.2405 9.45122 13.0888 9.18117 12.8188L5.99997 9.18281L2.81877 12.8176C2.68545 12.9531 2.52663 13.0609 2.35145 13.1347C2.17628 13.2085 1.98823 13.2469 1.79814 13.2477C1.60805 13.2485 1.41969 13.2116 1.24392 13.1392C1.06815 13.0668 0.908454 12.9604 0.77404 12.8259C0.639626 12.6915 0.533155 12.5318 0.460769 12.3561C0.388382 12.1803 0.351512 11.9919 0.352286 11.8018C0.35306 11.6118 0.391462 11.4237 0.465278 11.2485C0.539094 11.0734 0.646862 10.9145 0.782367 10.7812L4.09197 7.00121L0.781167 3.21881C0.645662 3.0855 0.537894 2.92667 0.464078 2.7515C0.390263 2.57633 0.35186 2.38827 0.351086 2.19818C0.350312 2.00809 0.387182 1.81973 0.459569 1.64397C0.531955 1.4682 0.638426 1.3085 0.77284 1.17409C0.907255 1.03967 1.06695 0.933201 1.24272 0.860814C1.41849 0.788427 1.60685 0.751557 1.79694 0.752331C1.98703 0.753105 2.17508 0.791508 2.35025 0.865323C2.52543 0.939139 2.68425 1.04691 2.81757 1.18241L5.99997 4.81961L9.18117 1.18241C9.31448 1.04691 9.47331 0.939139 9.64848 0.865323C9.82365 0.791508 10.0117 0.753105 10.2018 0.752331C10.3919 0.751557 10.5802 0.788427 10.756 0.860814C10.9318 0.933201 11.0915 1.03967 11.2259 1.17409C11.3603 1.3085 11.4668 1.4682 11.5392 1.64397C11.6116 1.81973 11.6484 2.00809 11.6476 2.19818C11.6469 2.38827 11.6085 2.57633 11.5347 2.7515C11.4608 2.92667 11.3531 3.0855 11.2176 3.21881L7.90797 7.00121L11.2176 10.7812C11.3515 10.915 11.4577 11.0738 11.5301 11.2486C11.6026 11.4234 11.6399 11.6108 11.6399 11.8C11.6399 11.9893 11.6026 12.1766 11.5301 12.3514C11.4577 12.5263 11.3515 12.6851 11.2176 12.8188Z"
                      fill="black"
                      fillOpacity="0.53"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
          {modals.otpVerify && (
            <DialogBox show={modals.otpVerify}>
              <div className="bg-primary-white flex flex-col justify-between relative">
                <div className=" border-[1.5px] border-solid border-primary-dark-10 p-6  w-[902px] h-[370px]">
                  <h2 className="text-heading font-semibold text-left mb-4">
                    OTP has been sent for User Authentication
                  </h2>
                  <p className="text-secondary-light text-h5 text-justify mb-8">
                    One Time Password had been sent to your Mobile no. and Email
                    Address. Please enter the verification code.
                  </p>
                  <div className="flex items-center mb-8">
                    {otp.map((element, index) => {
                      return (
                        <input
                          type="text"
                          key={index}
                          onChange={(e) => handleChnges(e.target, index)}
                          value={element}
                          name="otp"
                          maxLength="1"
                          onFocus={(e) => e.target.select()}
                          autoFocus={index === 0}
                          className="font-semibold text-heading text-h1 text-center mr-[19px] rounded-lg bg-primary-white w-[61.16px] h-[54px] border-1 border-solid border-[rgba(239, 239, 238, 0.9)] shadow-[0_3px_4px_3px_rgba(99,99,99,0.11)]"
                        />
                      );
                    })}
                  </div>
                  <p className="mb-6 text-left text-heading-false font-semibold text-p1">
                    Didn't receive the OTP?{" "}
                    <span className="text-secondary-light underline">
                      <Timeout
                        handleClick={() =>
                          booksInCart?.bookData.length > 0
                            ? generateOrderId()
                            : generateOrderIdforSets()
                        }
                        start={60}
                      />
                    </span>
                  </p>
                  <div className="flex justify-end">
                    {otp[5] === "" ? (
                      <BtnSmallDisabled>Submit</BtnSmallDisabled>
                    ) : (
                      <BtnSmall
                        onClick={() => {
                          booksInCart?.bookData.length > 0
                            ? verifyOtp()
                            : verifyOtpset();
                        }}
                      >
                        Submit
                      </BtnSmall>
                    )}
                  </div>
                </div>

                <button
                  onClick={() => {
                    setModals((prevState) => ({
                      ...prevState,
                      otpVerify: false,
                    }));
                    setOtp([...otp.map((v) => "")]);
                  }}
                  className="absolute top-8 right-8"
                >
                  <svg
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2176 12.8188C10.9475 13.0888 10.5813 13.2405 10.1994 13.2405C9.81747 13.2405 9.45122 13.0888 9.18117 12.8188L5.99997 9.18281L2.81877 12.8176C2.68545 12.9531 2.52663 13.0609 2.35145 13.1347C2.17628 13.2085 1.98823 13.2469 1.79814 13.2477C1.60805 13.2485 1.41969 13.2116 1.24392 13.1392C1.06815 13.0668 0.908454 12.9604 0.77404 12.8259C0.639626 12.6915 0.533155 12.5318 0.460769 12.3561C0.388382 12.1803 0.351512 11.9919 0.352286 11.8018C0.35306 11.6118 0.391462 11.4237 0.465278 11.2485C0.539094 11.0734 0.646862 10.9145 0.782367 10.7812L4.09197 7.00121L0.781167 3.21881C0.645662 3.0855 0.537894 2.92667 0.464078 2.7515C0.390263 2.57633 0.35186 2.38827 0.351086 2.19818C0.350312 2.00809 0.387182 1.81973 0.459569 1.64397C0.531955 1.4682 0.638426 1.3085 0.77284 1.17409C0.907255 1.03967 1.06695 0.933201 1.24272 0.860814C1.41849 0.788427 1.60685 0.751557 1.79694 0.752331C1.98703 0.753105 2.17508 0.791508 2.35025 0.865323C2.52543 0.939139 2.68425 1.04691 2.81757 1.18241L5.99997 4.81961L9.18117 1.18241C9.31448 1.04691 9.47331 0.939139 9.64848 0.865323C9.82365 0.791508 10.0117 0.753105 10.2018 0.752331C10.3919 0.751557 10.5802 0.788427 10.756 0.860814C10.9318 0.933201 11.0915 1.03967 11.2259 1.17409C11.3603 1.3085 11.4668 1.4682 11.5392 1.64397C11.6116 1.81973 11.6484 2.00809 11.6476 2.19818C11.6469 2.38827 11.6085 2.57633 11.5347 2.7515C11.4608 2.92667 11.3531 3.0855 11.2176 3.21881L7.90797 7.00121L11.2176 10.7812C11.3515 10.915 11.4577 11.0738 11.5301 11.2486C11.6026 11.4234 11.6399 11.6108 11.6399 11.8C11.6399 11.9893 11.6026 12.1766 11.5301 12.3514C11.4577 12.5263 11.3515 12.6851 11.2176 12.8188Z"
                      fill="black"
                      fillOpacity="0.53"
                    />
                  </svg>
                </button>
              </div>
            </DialogBox>
          )}

          {modals.assignmentSuccessful && (
            <DialogBox show={modals.assignmentSuccessful}>
              <div className="bg-primary-white flex flex-col relative w-full lg:w-pop-up  p-8">
                <h4 className="text-h4 text-tertiary-dark font-bold mb-[36px]">
                  Book Assignment Successful
                </h4>
                <h5 className="text-heading font-bold mb-[14px] ">
                  {/* Lorem ipsum isiijhr sitamet, consectetur adipsicing */}
                  The Book has been assigned to the user.
                </h5>
                <p className="text-secondary-light mb-[14px]">
                 The user can now read this book on their ePrakashak Readers.
                </p>

                <div className="text-center lg:text-right">
                  <button
                    onClick={() => navigation(ROUTES.USERS)}
                    className="w-full max-w-[372px] lg:w-[150px] h-[56px] bg-primary-dark text-primary-white text-mobile-h5 lg:text-h5"
                  >
                    Back to Users
                  </button>
                </div>

                <button
                  onClick={() =>
                    setModals((prevState) => ({
                      ...prevState,
                      assignmentSuccessful: false,
                    }))
                  }
                  className="absolute top-8 right-8"
                >
                  <svg
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2176 12.8188C10.9475 13.0888 10.5813 13.2405 10.1994 13.2405C9.81747 13.2405 9.45122 13.0888 9.18117 12.8188L5.99997 9.18281L2.81877 12.8176C2.68545 12.9531 2.52663 13.0609 2.35145 13.1347C2.17628 13.2085 1.98823 13.2469 1.79814 13.2477C1.60805 13.2485 1.41969 13.2116 1.24392 13.1392C1.06815 13.0668 0.908454 12.9604 0.77404 12.8259C0.639626 12.6915 0.533155 12.5318 0.460769 12.3561C0.388382 12.1803 0.351512 11.9919 0.352286 11.8018C0.35306 11.6118 0.391462 11.4237 0.465278 11.2485C0.539094 11.0734 0.646862 10.9145 0.782367 10.7812L4.09197 7.00121L0.781167 3.21881C0.645662 3.0855 0.537894 2.92667 0.464078 2.7515C0.390263 2.57633 0.35186 2.38827 0.351086 2.19818C0.350312 2.00809 0.387182 1.81973 0.459569 1.64397C0.531955 1.4682 0.638426 1.3085 0.77284 1.17409C0.907255 1.03967 1.06695 0.933201 1.24272 0.860814C1.41849 0.788427 1.60685 0.751557 1.79694 0.752331C1.98703 0.753105 2.17508 0.791508 2.35025 0.865323C2.52543 0.939139 2.68425 1.04691 2.81757 1.18241L5.99997 4.81961L9.18117 1.18241C9.31448 1.04691 9.47331 0.939139 9.64848 0.865323C9.82365 0.791508 10.0117 0.753105 10.2018 0.752331C10.3919 0.751557 10.5802 0.788427 10.756 0.860814C10.9318 0.933201 11.0915 1.03967 11.2259 1.17409C11.3603 1.3085 11.4668 1.4682 11.5392 1.64397C11.6116 1.81973 11.6484 2.00809 11.6476 2.19818C11.6469 2.38827 11.6085 2.57633 11.5347 2.7515C11.4608 2.92667 11.3531 3.0855 11.2176 3.21881L7.90797 7.00121L11.2176 10.7812C11.3515 10.915 11.4577 11.0738 11.5301 11.2486C11.6026 11.4234 11.6399 11.6108 11.6399 11.8C11.6399 11.9893 11.6026 12.1766 11.5301 12.3514C11.4577 12.5263 11.3515 12.6851 11.2176 12.8188Z"
                      fill="black"
                      fillOpacity="0.53"
                    />
                  </svg>
                </button>
              </div>
            </DialogBox>
          )}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
          />
        </>
      )}
    </Page>
  );
}
