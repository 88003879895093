import { useState, useEffect } from "react";
import { Page, Navbar, BtnBlock, DialogBox, BtnSmall } from "../components";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import HeaderIcon from "../components/Header_icon";
import Loader from "../components/loader";
import { getsetdetail } from "../assets/Js/script";
import * as ROUTES from "../constant/routes";
import { ToastContainer, toast } from "react-toastify";


export default function SetDetail() {
  const [modals, setModals] = useState({
    addNew: false,
  });

  const navigate = useNavigate();
  const notify = (message) => toast(message);


  const Params = useParams();
  const [read, setRead] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [books, setBooks] = useState(null);

  // api////

  useEffect(() => {
    async function getdetails() {
      const { response, error } = await getsetdetail(Params.bookId);
      setIsLoading(true);
      if (response.status === "success") {
        console.log(response, "response");
        setIsLoading(false);
        setBooks({ ...response.data });
      }else {
        notify(response?.message ? response?.message : "Something went wrong");
        setIsLoading(false);
      }
    }
    getdetails();
  }, []);


  ////////// api ///////

  return (
    <Page active="wallet">
      <Navbar />
      {isLoading ? (
        <Loader show={isLoading} />
      ) : (
        <>
          <div className="flex flex-col">
            <h2 className="hidden lg:block text-heading text-h4 font-semibold text-left">
              <button className="mr-3" onClick={() => navigate(-1)}>
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.05363 15.7514C6.87311 15.752 6.69475 15.7149 6.53165 15.6429C6.36855 15.5708 6.22486 15.4657 6.11113 15.3352L0.274881 8.58516C0.0971576 8.38386 0 8.13136 0 7.87078C0 7.61021 0.0971576 7.35771 0.274881 7.15641L6.31655 0.406409C6.52165 0.176665 6.81638 0.0321876 7.13589 0.00476044C7.45541 -0.0226668 7.77354 0.0692029 8.0203 0.260159C8.26706 0.451115 8.42224 0.725515 8.4517 1.02299C8.48116 1.32047 8.38248 1.61666 8.17738 1.84641L2.77613 7.87641L7.99613 13.9064C8.14389 14.0715 8.23775 14.2726 8.2666 14.4859C8.29546 14.6991 8.2581 14.9156 8.15895 15.1097C8.0598 15.3038 7.90301 15.4674 7.70712 15.5811C7.51124 15.6948 7.28446 15.7539 7.05363 15.7514Z"
                    fill="#494949"
                  />
                </svg>
              </button>
              Book Details: {books?.setDetail.name}
            </h2>
            <HeaderIcon heading={"Book Details"} />
            <h2 className="lg:hidden text-heading text-h4 font-semibold text-left">
              {books?.setDetail.name}
            </h2>
          </div>
          <div className="my-6 flex justify-between flex-col lg:flex-row">
            <div className="lg:w-8/12">
              <div className="flex flex-col lg:flex-row">
                <div className="flex lg:block">
                  <div className="overflow-hidden h-[309px]">
                    <img
                      className="h-full object-cover w-full"
                      src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/setCovers/${books?.setDetail.cover}`}
                      alt="book front cover"
                    />
                  </div>
                  {/* <div className="flex flex-col lg:flex-row justify-start ml-4 lg:ml-0 lg:mt-4">
                    <div className="mr-4 w-[58px] h-16 lg:h-12 bg-neutral-10">
                      <img
                        className=" h-full object-cover w-full"
                        src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/setCovers/${books?.SetDetail.cover}`}
                        alt="front cover"
                      />
                    </div>
                    <div className="mr-4 w-[58px] h-16 lg:h-12 bg-neutral-10">
                      <img
                        className="h-full object-cover w-full"
                        src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/setCovers/${books?.SetDetail.imageCoverBack}`}
                        alt="back cover"
                      />
                    </div>
                  </div> */}
                </div>
                <div className="py-4 flex flex-col justify-start gap-3 px-2">
                  <h1 className="hidden lg:block text-heading text-h1 font-semibold text-left ">
                    {books?.setDetail?.title}
                  </h1>
                  <hr className="hidden lg:block text-secondary-dark " />
                  <p className="text-tertiary-dark lg:uppercase tracking-wide font-semibold text-h5 my-3 lg:my-0">
                    Description
                  </p>
                  <p className="text-secondary-dark text-h5 tracking-[0.01em] leading-6 text-left">
                    {/* {read ? ( */}
                      <span>{books?.setDetail.description}</span>
                    {/* ) : (
                      <span>{books?.SetDetail?.shortDescription}</span>
                    )} */}
                    {/* <span
                      onClick={() => setRead(!read)}
                      className="text-tertiary-dark underline"
                    >
                      Read more
                    </span> */}
                  </p>
                  {/* <p className="text-secondary-dark text-h4 lg:text-p1 text-left mt-6 lg:mt-0">
                    E-Book Price:{" "}
                    <span className="text-h5 text-[#B6B6B6] lg:text-heading font-semibold">
                      &#8377;{books?.SetDetail.eBookPrice}
                    </span>
                  </p> */}
                </div>
              </div>
              <div className="inline-block lg:hidden bg-thumb text-primary-white px-6 py-2 mx-2 mb-12 my-2 ">
                <p>+ Add new</p>
              </div>

              {/* <p className="hidden lg:block  text-left my-4 text-heading text-sm font-semibold">
                See all photos
              </p> */}

              {/* <h3 className="hidden lg:block uppercase text-h5 text-tertiary-dark text-left my-4 font-semibold">
                request access id
              </h3> */}
              {/* <p className="hidden lg:block text-h5 text-secondary-dark tracking-wide text-left mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus
                ut vivamus vitae porttitor lsit amet, consectetur adipiscing
                elitsit amet, consectetur adipiscing elit.
              </p> */}
              <h2 className="hidden lg:block uppercase lg:text-laptop-h3 xl:text-h4  text-tertiary-dark font-semibold text-left mb-4 mt-12">
                LIST OF BOOKS IN THE SET
              </h2>
              <p className="hidden lg:block text-h5 text-secondary-dark tracking-wide text-left mb-4">
               
              </p>
              {books?.setDetail.Books.length > 0 && (
                <div className="flex flex-wrap gap-3">
                  {books?.setDetail.Books.map((data, index) => (
                    <div
                      key={data.parentId}
                      className="flex w-[266px] h-[134px] justify-center items-start bg-primary-extra-light mr-6 p-2"
                    >
                      <div style={{background: data?.backgroundColor}} className="w-[106px] h-full mr-[11px] bg-[#A93030] flex justify-center items-center py-4">
                        <img
                          className="shadow-[5px_5px_13px_3px_rgba(0,0,0,0.5)] h-[95px] w-[75%] object-cover"                            
                          src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${data.imageCoverFront}`}
                          alt="book"
                        />
                      </div>
                      <div className="w-[160px] font-semibold">
                        <h2 className="text-[303030] mt-3 text-mobile-h5">
                          {data.title}
                        </h2>
                        {/* <h3 className="text-[#5F5D5D8F] text-mobile-p1">
                          {data.Bauthors[0].bookAuthorName}
                        </h3> */}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="lg:w-[30%] lg:min-w-[305px] mr-2 lg:mr-0">
              <div className="pl-2 lg:pl-4 p-4 border-1 border-solid bg-[#FBFAFB] lg:bg-primary-white border-primary-dark-20 w-full ">
                <h2 classNaame="text-h4 font-bold text-[20px] lg:text-h4 text-left mb-2">
                  Details
                </h2>
                <div className="my-4 mb-0 flex justify-between">
                  <div className="w-1/2">
                    <div className="mb-4">
                      <h3 className="text-h5 text-secondary-dark font-semibold">
                        No. of Books
                      </h3>
                      <p className="text-h4 text-tertiary-dark font-semibold">
                        {books?.setDetail.Books.length}
                      </p>
                    </div>

                    {/* <div className="mb-4">
                      <h3 className="text-h5 text-secondary-dark font-semibold">
                        Book ID
                      </h3>
                      <p className="text-h4 whitespace-nowrap overflow-hidden mr-6 text-ellipsis text-tertiary-dark font-semibold">
                        {books?.setDetail.id}
                      </p>
                    </div> */}

                    <div className="mb-4">
                      <h3 className="text-h5 text-secondary-dark font-semibold">
                        Page Count
                      </h3>
                      <p className="text-h4 text-tertiary-dark font-semibold">
                        {books?.totalPage} pages

                      </p>
                    </div>

                    {/* <div className="mb-4">
                      <h3 className="text-h5 text-secondary-dark font-semibold">
                        Printed book
                      </h3>
                      {books?.setDetail?.printedBookAvailable ? (
                        <p className="text-h4 text-tertiary-dark font-semibold">
                          Available
                        </p>
                      ) : (
                        <p className="text-h4 text-tertiary-dark font-semibold">
                          Unavailable
                        </p>
                      )}
                    </div> */}

                    {/* <div className="mb-4">
                      <h3 className="text-h5 text-secondary-dark font-semibold">
                        ISBN NO.
                      </h3>
                      <p className="text-h4 text-tertiary-dark font-semibold">
                        {books?.setDetail?.ISBN}
                      </p>
                    </div>*/}
                  </div> 

                 <div className="w-1/2">
                      {/*<div className="mb-4">
                      <h3 className="text-h5 text-secondary-dark font-semibold">
                        Category
                      </h3>
                      {books?.setDetail?.Bcategories.map((elm) => (
                        <p className="text-h4 text-tertiary-dark font-semibold">
                          {elm.bookCategoryName}
                        </p>
                      ))}
                    </div> */}

                    <div className="mb-4">
                      <h3 className="text-h5 text-secondary-dark font-semibold">
                        Author
                      </h3>
                      {books?.setDetail?.Bauthors.map((elm) => (
                        <p className="text-h4 text-tertiary-dark font-semibold">
                          {elm.bookAuthorName}
                        </p>
                      ))}
                    </div>

                    <div className="mb-4">
                      <h3 className="text-h5 text-secondary-dark font-semibold">
                        Publishers
                      </h3>
                      {books?.setDetail?.Bpublications.map((elm) => (
                        <p className="text-h4 text-tertiary-dark font-semibold">
                          {elm.bookPublicationName}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="lg:hidden my-10 mt-11">
            <h3 className=" text-h5 text-tertiary-dark text-left my-4 font-semibold">
              Request Access ID
            </h3>
            <p className="text-h5 text-secondary-dark tracking-wide text-left mb-4 pr-1">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus ut
              vivamus vitae porttitor lsit amet, consectetur adipiscing elitsit
              amet, consectetur adipiscing elit.
            </p>
          </div> */}
          <div className="lg:border-0 border-1 border-solid border-primary-dark-20 bg-[#FBFAFB] lg:bg-primary-white p-2 lg:p-0 my-1 lg:my-0">
            <div className="grid grid-cols-[repeat(4,_50%)] lg:grid-cols-[repeat(4,_20%)]  h-fit overflow-hidden lg:overflow-visible gap-1 lg:gap-0 overflow-x-scroll auto-row-min w-[100%] lg:w-[95%] max-w-[1100px] lg:my-6">
              <p className="flex justify-center h-[46px] items-center text-center border-1 border-solid border-[#cacaca] lg:border-secondary-light text-secondary-dark text-h5 font-semibold">
                Validity(in years)
              </p>
              <p className="flex justify-center h-[46px] items-center text-center border-1 border-solid border-[#cacaca] lg:border-secondary-light text-secondary-dark text-h5 font-semibold">
                No. of total access ids
              </p>
              <p className="flex justify-center h-[46px] items-center text-center border-1 border-solid border-[#cacaca] lg:border-secondary-light text-secondary-dark text-h5 font-semibold">
                No. of alloted access ids
              </p>
              <p className="flex justify-center h-[46px] items-center text-center border-1 border-solid border-[#cacaca] lg:border-secondary-light text-secondary-dark text-h5 font-semibold">
                No. of available access ids
              </p>
              {books?.availableCodes?.map((data, index) => 
                <>
                  <p className="flex justify-center h-[46px] items-center text-center border-1 border-solid border-[#cacaca] lg:border-secondary-light text-secondary-dark text-h5 font-semibold">
                    {data.validity}
                  </p>
                  <p className="flex justify-center h-[46px] items-center text-center border-1 border-solid border-[#cacaca] lg:border-secondary-light text-secondary-dark text-h5 font-semibold">
                    {data.totalCount}
                  </p>
                  <p className="flex justify-center h-[46px] items-center text-center border-1 border-solid border-[#cacaca] lg:border-secondary-light text-secondary-dark text-h5 font-semibold">
                   {(data.totalCount) - (data.leftCount)}
                  </p>
                  <p className="flex justify-center h-[46px] items-center text-center border-1 border-solid border-[#cacaca] lg:border-secondary-light text-secondary-dark text-h5 font-semibold">
                    {data.leftCount}
                  </p>
                </>
              )}
            </div>
          </div>
          {/* <BtnSmall>Request</BtnSmall> */}
          {modals.addNew && (
            <DialogBox>
              <div className="bg-primary-white flex flex-col justify-between relative w-pop-up h-pop-up p-8">
                <h4 className="text-h4 text-tertiary-dark font-bold">
                  Select one option
                </h4>

                <p className="text-secondary-light">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
                  utveetiis ibulum bibendum egestas.
                </p>
                <div className="flex flex-col h-16 justify-between">
                  <div className="flex items-center ">
                    <input
                      name="checkbox-1"
                      type="radio"
                      checked="true"
                      className="w-4 h-4 rounded-full bg-primary-dark mr-4"
                    />
                    <label
                      for="checkbox-1"
                      className="text-heading font-semibold text-h5"
                    >
                      Add new chapter
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      name="checkbox-2"
                      type="radio"
                      checked="true"
                      className="w-4 h-4 rounded-full bg-primary-dark mr-4"
                    />
                    <label
                      for="checkbox-2"
                      className="text-heading font-semibold text-h5"
                    >
                      Add new addendum
                    </label>
                  </div>
                </div>
                <div className="text-right">
                  <BtnSmall
                    onClick={() =>
                      setModals((prevState) => ({
                        ...prevState,
                        addNew: false,
                      }))
                    }
                  >
                    Confirm
                  </BtnSmall>
                </div>
                <button
                  onClick={() =>
                    setModals((prevState) => ({ ...prevState, addNew: false }))
                  }
                  className="absolute top-8 right-8"
                >
                  <svg
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2176 12.8188C10.9475 13.0888 10.5813 13.2405 10.1994 13.2405C9.81747 13.2405 9.45122 13.0888 9.18117 12.8188L5.99997 9.18281L2.81877 12.8176C2.68545 12.9531 2.52663 13.0609 2.35145 13.1347C2.17628 13.2085 1.98823 13.2469 1.79814 13.2477C1.60805 13.2485 1.41969 13.2116 1.24392 13.1392C1.06815 13.0668 0.908454 12.9604 0.77404 12.8259C0.639626 12.6915 0.533155 12.5318 0.460769 12.3561C0.388382 12.1803 0.351512 11.9919 0.352286 11.8018C0.35306 11.6118 0.391462 11.4237 0.465278 11.2485C0.539094 11.0734 0.646862 10.9145 0.782367 10.7812L4.09197 7.00121L0.781167 3.21881C0.645662 3.0855 0.537894 2.92667 0.464078 2.7515C0.390263 2.57633 0.35186 2.38827 0.351086 2.19818C0.350312 2.00809 0.387182 1.81973 0.459569 1.64397C0.531955 1.4682 0.638426 1.3085 0.77284 1.17409C0.907255 1.03967 1.06695 0.933201 1.24272 0.860814C1.41849 0.788427 1.60685 0.751557 1.79694 0.752331C1.98703 0.753105 2.17508 0.791508 2.35025 0.865323C2.52543 0.939139 2.68425 1.04691 2.81757 1.18241L5.99997 4.81961L9.18117 1.18241C9.31448 1.04691 9.47331 0.939139 9.64848 0.865323C9.82365 0.791508 10.0117 0.753105 10.2018 0.752331C10.3919 0.751557 10.5802 0.788427 10.756 0.860814C10.9318 0.933201 11.0915 1.03967 11.2259 1.17409C11.3603 1.3085 11.4668 1.4682 11.5392 1.64397C11.6116 1.81973 11.6484 2.00809 11.6476 2.19818C11.6469 2.38827 11.6085 2.57633 11.5347 2.7515C11.4608 2.92667 11.3531 3.0855 11.2176 3.21881L7.90797 7.00121L11.2176 10.7812C11.3515 10.915 11.4577 11.0738 11.5301 11.2486C11.6026 11.4234 11.6399 11.6108 11.6399 11.8C11.6399 11.9893 11.6026 12.1766 11.5301 12.3514C11.4577 12.5263 11.3515 12.6851 11.2176 12.8188Z"
                      fill="black"
                      fillOpacity="0.53"
                    />
                  </svg>
                </button>
              </div>
            </DialogBox>
          )}
        </>
      )}
       <ToastContainer
            position="top-right"
            autoClose={3000}
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
      />
    </Page>
  );
}
