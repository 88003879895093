import { useState } from "react";
import { createPortal } from "react-dom";
import Input from "../../components/form/input";
import { BtnBlock, BtnBlockDisabled, DialogBox, PopUp } from "../../components";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as ROUTES from "../../constant/routes";
import login1 from "../../assets/images/login/login1.png";
import Loader from "../../components/loader";
import { handleForgotPassword } from "../../assets/Js/script";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [resend, setResend] = useState(false);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const navigate = useNavigate();
  const [slideShowButtons, setSlideShowButtons] = useState([
    { id: 0, active: true },
    { id: 1, active: false },
    { id: 2, active: false },
  ]);


  function handleChange(e) {
    setEmail(e.target.value);
  }

  
  const notify = () =>
    toast.warn("No user found with this email", {
      position: "top-center",
    });

    async function handleSubmit(e) {
      e.preventDefault();
      setIsLoaderActive(true)
      const { response } = await handleForgotPassword(email);
      if ((await response.status) !== "fail") {
        setIsLoaderActive(false);
        setShowPopup(true);
      } else {
        setIsLoaderActive(false);
        notify();
      }
    }

  function handleClose(e) {
    setShowPopup(false);
    setResend(true);
  }

  return (
    <div className="min-w-screen flex min-h-screen box-border">
      <div className="w-full py-8 px-5 lg:p-0 lg:w-3/5 flex justify-center items-center-start lg:items-center lg:border-r-8  lg:border-r-secondary-yellow lg:border-solid">
        <form
          className="flex justify-between flex-col w-354 h-[340px] lg:h-96 "
          onSubmit={handleSubmit}
        >
          <h1 className="text-mobile-h1 lg:text-h1 text-tertiary-dark font-bold">
            Forgot Password
          </h1>
          <p className="mb-4 lg:mb-0 text-mobile-p1 lg:text-p1 text-secondary-light text-justify font-semibold">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, leo
            sed turpis mattis risus aliquet tellus a e.
          </p>

          <Input
            label="Email address"
            type="email"
            name="email"
            placeholder="Enter Email"
            value={email}
            onChange={handleChange}
            required
          />
          <br className="my-6" />

          {email === "" ? (
            <BtnBlockDisabled>Continue</BtnBlockDisabled>
          ) : (
            <BtnBlock 
            >
            Continue
            </BtnBlock>
          )}
          <p className="text-mobile-h4 lg:text-h4 mt-2 text-center font-semibold">
            Remember password?{" "}
            <RouterLink
              to={ROUTES.LOG_IN}
              className="text-tertiary-dark text-mobile-p1 lg:text-h5 border-b-2 border-b-solid border-b-primary-dark"
            >
              Login
            </RouterLink>
          </p>
        </form>
      </div>
      <div className="hidden lg:flex w-2/5 h-screen justify-center items-center relative bg-primary-dark">
        <div className="bg-primary-dark flex flex-col items-center w-11/12 ">
          <div className="">
            <img
              className="h-full block"
              src={login1}
              alt="login slideshow 1"
            />
          </div>
          <p className="text-secondary-light mt-10 text-h4 font-semibold text-center">
            Lorem ipsum dolor sit amet,
            <br /> consectetur adi
          </p>
          <div className="w-24 h-4 mt-10 flex justify-evenly mx-auto items-center">
            {slideShowButtons.map((item) =>
              item.active ? (
                <button
                  key={item.id}
                  className="w-8 h-2 bg-white rounded-l-xl rounded-r-xl"
                ></button>
              ) : (
                <button
                  key={item.id}
                  className="w-2 h-2 bg-white rounded-full "
                ></button>
              )
            )}
          </div>
        </div>

        {/* relative elements */}
        <div className="absolute top-8 -left-12">
          <svg
            width="115"
            height="164"
            viewBox="0 0 115 164"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_1766_5585)">
              <path
                d="M71.6674 140.24C86.3853 124.86 124.585 92.5501 109.206 51.2368C93.8275 9.92345 2.64301 -36.1161 2.71204 54.3921C2.73783 88.394 -0.691774 137.369 9.64573 151.697C26.8222 175.508 62.48 149.839 71.6674 140.24Z"
                fill="#ECEDFF"
                fillOpacity="0.3"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1766_5585"
                x="0.186523"
                y="0.140991"
                width="114.603"
                height="163.396"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1"
                  result="effect1_foregroundBlur_1766_5585"
                />
              </filter>
            </defs>
          </svg>
        </div>

        <div className="absolute top-0 right-0">
          <svg
            width="77"
            height="148"
            viewBox="0 0 77 148"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_1766_5588)">
              <path
                d="M38.5882 9.9185C24.8818 26.4457 -11.2997 61.7583 7.60116 101.69C26.502 141.621 122.697 180.101 115.404 89.8871C112.665 55.9957 112.237 6.89761 100.605 -6.54347C81.2764 -28.8822 47.1443 -0.396586 38.5882 9.9185Z"
                fill="#ECEDFF"
                fillOpacity="0.3"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1766_5588"
                x="0.25"
                y="-16.8807"
                width="117.548"
                height="163.97"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1"
                  result="effect1_foregroundBlur_1766_5588"
                />
              </filter>
            </defs>
          </svg>
        </div>

        <div className="absolute bottom-8 left-0">
          <svg
            width="115"
            height="164"
            viewBox="0 0 115 164"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_1766_5585)">
              <path
                d="M71.6674 140.24C86.3853 124.86 124.585 92.5501 109.206 51.2368C93.8275 9.92345 2.64301 -36.1161 2.71204 54.3921C2.73783 88.394 -0.691774 137.369 9.64573 151.697C26.8222 175.508 62.48 149.839 71.6674 140.24Z"
                fill="#ECEDFF"
                fillOpacity="0.3"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1766_5585"
                x="0.186523"
                y="0.140991"
                width="114.603"
                height="163.396"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1"
                  result="effect1_foregroundBlur_1766_5585"
                />
              </filter>
            </defs>
          </svg>
        </div>

        <div className="absolute bottom-8 right-0">
          <svg
            width="50"
            height="165"
            viewBox="0 0 50 165"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_1766_5589)">
              <path
                d="M38.5882 26.9185C24.8818 43.4457 -11.2997 78.7583 7.60116 118.69C26.502 158.621 122.697 197.101 115.404 106.887C112.665 72.9957 112.237 23.8976 100.605 10.4565C81.2764 -11.8822 47.1443 16.6034 38.5882 26.9185Z"
                fill="#ECEDFF"
                fillOpacity="0.3"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1766_5589"
                x="0.25"
                y="0.119263"
                width="117.548"
                height="163.97"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1"
                  result="effect1_foregroundBlur_1766_5589"
                />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
      {showPopup &&
        createPortal(
          <DialogBox show={showPopup}>
            <PopUp
              button="Proceed to login"
              title="Mail Sent!"
              main="Please check your inbox for a recovery mail."
              body="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget utvest-ibulum bibendum egestas. Enim mi arcu convallis enim purus interdum. Ut neque at adipiscing tortor facilisis sagittis libero. Ac bibendum vel blandit."
              onClose={handleClose}
              onClick={() => navigate(ROUTES.LOG_IN)}
            />
          </DialogBox>,
          document.body
        )}
        <Loader show={isLoaderActive} />
        <ToastContainer position="top-center" />
    </div>
  );
}
