import { useState, useEffect } from "react";
import { Page, Navbar, BtnBlock, DialogBox, BtnSmall } from "../components";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as ROUTES from "../constant/routes";
import { getAllSets } from "../assets/Js/script";
import HeaderIcon from "../components/Header_icon";
import Loader from "../components/loader";

export default function Set() {
  const [modals, setModals] = useState({
    expandedMenu: false,
    generateIds: false,
  });
  const navigate = useNavigate();
  const navigation = useNavigate();
  const [expiringSoon, setExpiringSoon] = useState([]);
  const [books, setBooks] = useState(null);
  // access ids
  const [accessIds, setAccessIds] = useState([]);
  const [accessIdSelected, setAccessIdSelected] = useState(true);
  const [generateAccessIds, setGenerateAccessIds] = useState(false);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  function handleIdType({ target }) {
    const { name } = target;
    setAccessIdSelected(name === "access_id" ? true : false);
  }

  function handleSelection(book) {
    setSelected((prevState) => {
      if (prevState.includes(book.id)) {
        return prevState.filter((item) => item !== book.id);
      } else {
        return [...prevState, book.id];
      }
    });
  }

  useEffect(() => {
    async function temp() {
      setIsLoading(true);
      const { response, error } = await getAllSets();
      if (response.status === "success") {
        console.log(response, "response");
        setBooks([...response.data.sets]);
        setAccessIds([...response.data.counts]);
        setIsLoading(false);
      }
    }
    temp();
  }, []);

  function getNoofAccessCode(id) {
    if (accessIds.length > 0) {
      return accessIds.find((item) => item.parentId === id).count;
    }
  }

  console.log(books, "set-book");
  return (
    <Page active="wallet">
      <Navbar />
      {isLoading ? (<Loader show={isLoading} />) : (<>

      <div className="flex ">
        <div className={`${modals.expandedMenu ? "w-expanded" : "w-full"}`}>
          <div className={`flex justify-between items-center w-full`}>
            <h1 className=" hidden lg:block lg:text-heading lg:text-left font-semibold text-montserrat lg:text-h1 my-4">
              Library- Sets
            </h1>
            <div className="hidden lg:flex items-center">
              {generateAccessIds ? (
                <div className="hidden lg:block">
                  <button
                    onClick={() => setGenerateAccessIds(false)}
                    className="mr-6 text-heading-false text-mobile-p1 sm:text-tablet-p1 lg:text-p1 font-[500] outline-0 border-0"
                  >
                    Cancel
                  </button>
                  {accessIdSelected ? (
                    <button
                      onClick={() => navigation(ROUTES.GENERATE_ACCESS_IDS)}
                      className="hover:border-primary-dark rounded-sm mr-10 font-semibold w-[225px] h-[54px] text-tertiary-dark text-mobile-h5 sm:text-tablet-h5 lg:text-h5 border-1 border-solid border-primary-dark-10"
                    >
                      Generate Access IDs
                    </button>
                  ) : (
                    <button
                      onClick={() => navigation(ROUTES.GENERATE_ORDER_IDS)}
                      className="hover:border-primary-dark rounded-sm mr-10 font-semibold w-[225px] h-[54px] text-tertiary-dark text-mobile-h5 sm:text-tablet-h5 lg:text-h5 border-1 border-solid border-primary-dark-10"
                    >
                      Generate Order IDs
                    </button>
                  )}
                </div>
              ) : (
                <button
                  onClick={() => navigation(ROUTES.ASSIGN_BOOKS)}
                  className="hidden lg:block underline text-tertiary-dark font-semibold text-mobile-p1 sm:text-tablet-p1 lg:text-p1 mr-4"
                >
                  Assign
                </button>
              )}
              {/* <p className="text-secondary-light mr-4">Sort by</p>
              <button className="hidden lg:flex justify-center items-center bg-neutral-10 w-[141px] h-8 text-secondary-dark text-mobile-p1 sm:text-tablet-p1 lg:text-p1 font-semibold">
                New arrival
                <span className="ml-2">
                  <svg
                    width="18"
                    height="15"
                    viewBox="0 0 18 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="18" height="15" rx="2" fill="#FFD600" />
                    <path d="M9.5 11L5 5H14L9.5 11Z" fill="#7A7976" />
                  </svg>
                </span>
              </button> */}
            </div>
          </div>
          <HeaderIcon heading={"Library"} sort={true} />
          {/* // */}
          <p className="text-secondary-light font-semibold text-h5 text-montserrat">
            View &#38; Manage all Sets in your Organisation
          </p>
          {books?.length > 0 ? (
            <div className="w-full gap-y-4 mb-[6rem] lg:gap-12 my-8 lg:mb-8 flex flex-wrap">
              {books?.map((book) => {
                return generateAccessIds ? (
                  <div
                    key={book.id}
                    className="flex flex-row rounded-sm shadow lg:drop-shadow-sm lg:rounded-none lg:block w-full lg:w-[260px] lg:h-[300px] border-1 border-solid border-primary-dark-20 scale-1 hover:scale-105 transition duration-600 hover:ease-in"
                  >
                    <div style={{background: book.backgroundColor}} className="flex justify-center items-center w-[98px] h-[109px] lg:h-[70%] lg:w-full border-r-1 border-solid border-primary-dark-20 lg:border-r-0 bg-neutral-10 relative">
                      <img
                        className="h-[85px] lg:h-[85%] object-cover shadow-[5px_5px_13px_3px_rgba(0,0,0,0.5)] "
                        src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/setCovers/${book.cover}`}
                        alt="front cover"
                      />
                      <div className="hidden absolute top-2 right-2 rounded-full lg:flex justify-center items-center bg-neutral-0 w-[91px] h-[89px]">
                        <p className="text-mobile-sm sm:text-tablet-sm lg:text-sm text-center leading-tight text-[rgba(112,41,99,0.7)]">
                          <span className="text-mobile-h3 sm:text-tablet-h3 lg:text-h3 text-tertiary-dark font-semibold">
                            {getNoofAccessCode(book.parentId)}
                          </span>
                          <br />
                          <span className="text-secondary-dark">
                            Access ids
                          </span>
                          {/* <br /> */}
                          {/* available */}
                        </p>
                      </div>
                    </div>
                    <div className="w-full h-[30%] p-2">
                      <p className="mt-1 py-1 sm:text-tablet-p1 lg:text-p1 text-[19px] text-heading font-semibold text-left leading-none">
                        {book.title}
                      </p>
                      <div className="flex justify-between items-center ">
                        <p className="text-secondary-dark capitalize text-mobile-p1 sm:text-tablet-p1 lg:text-p1 font-semibold">
                          {book.Bauthors.map((elm) => elm.bookAuthorName)}
                        </p>
                        {/* <p className="text-heading font-semibold text-mobile-h5 sm:text-tablet-h5 lg:text-h5">
                          {" "}
                          &#8377;{book.eBookPrice}
                        </p> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <RouterLink to={`/set/${book.id}`}>
                    <div
                      key={book.id}
                      className="flex flex-row rounded-sm shadow lg:drop-shadow-sm lg:rounded-none lg:block w-full lg:w-[260px] lg:h-[300px] border-1 border-solid border-primary-dark-20 scale-1 hover:scale-105 transition duration-600 hover:ease-in"
                    >
                      <div style={{background: book.backgroundColor}} className="flex justify-center items-center w-[98px] h-[109px] lg:h-[70%] lg:w-full border-r-1 border-solid border-primary-dark-20 lg:border-r-0 bg-neutral-10 relative">
                        <img
                          className="h-[85px] lg:h-[85%] object-cover shadow-[5px_5px_13px_3px_rgba(0,0,0,0.5)] "
                          src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/setCovers/${book.cover}`}
                          alt="front cover"
                        />
                        <div className="hidden absolute top-2 right-2 rounded-full lg:flex justify-center items-center bg-neutral-0 w-[91px] h-[89px]">
                          <p className="text-mobile-sm sm:text-tablet-sm lg:text-sm text-center leading-tight text-[rgba(112,41,99,0.7)]">
                            <span className="text-mobile-h3 sm:text-tablet-h3 lg:text-h3 text-tertiary-dark font-semibold">
                              {getNoofAccessCode(book.parentId)}
                            </span>
                            <br />
                            <span className="text-secondary-dark">
                              Access ids
                            </span>
                            {/* <br /> */}
                            {/* available */}
                          </p>
                        </div>
                      </div>
                      <div className="w-full h-[30%] p-2">
                        <p className="mt-1 py-1 sm:text-tablet-p1 lg:text-p1 text-[19px] text-heading font-semibold text-left leading-none">
                          {book.title}
                        </p>
                        <div className="flex justify-between items-center ">
                          <p className="text-secondary-dark capitalize text-mobile-p1 sm:text-tablet-p1 lg:text-p1 font-semibold">
                            {book.Bauthors.map((elm) => elm.bookAuthorName)}
                          </p>
                          {/* <p className="text-heading font-semibold text-mobile-h5 sm:text-tablet-h5 lg:text-h5">
                          {" "}
                          &#8377;{book.eBookPrice}
                        </p> */}
                        </div>
                      </div>
                    </div>
                  </RouterLink>
                );
              })}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-[70%]">
              <p className="mt-[170px] text-secondary-light text-montserrat font-semibold leading-[29px] text-h3">
              You do not have any Sets Purchased yet
              </p>
            </div>
          )}
        </div>

        {modals.expandedMenu && (
          <div className="w-354 mt-16 px-2">
            <div className="w-full flex justify-between mb-4">
              <h2 className="text-h3 text-heading font-semibold">
                Expiring soon
              </h2>
              <RouterLink
                to={ROUTES.EXPIRING_SOON}
                className="text-p1 text-secondary-dark font-semibold"
              >
                See more
              </RouterLink>
            </div>
            {/* {expiringSoon.map((book) => (
              <div className="flex rounded-sm mb-6 border-1 p-2 border-solid border-primary-dark-20 h-[148px]">
                <div className="bg-neutral-10 w-[103px] h-28"></div>
                <div className="py-4 px-4 flex flex-col justify-between">
                  <h3 className="text-heading text-left font-semibold text-h5">
                    {book.desc}
                  </h3>
                  <p className="text-p1 text-secondary-dark font-semibold">
                    {book.author}
                  </p>
                  <div className="flex justify-between items-center">
                    <div className="w-20 h-2 bg-primary-dark rounded-full"></div>
                    <p className="text-sm text-secondary-light ">
                      expiring in 29days
                    </p>
                  </div>
                </div>
              </div>
            ))} */}
          </div>
        )}
      </div>
      {modals.generateIds && (
        <DialogBox>
          <div className="bg-primary-white flex flex-col justify-between relative w-pop-up h-pop-up p-8">
            <h4 className="text-h4 text-tertiary-dark font-bold">
              Select one option
            </h4>
            <p className="text-secondary-light">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
              utveetiis ibulum bibendum egestas.
            </p>
            <div className="flex flex-col h-16 justify-between">
              <div className="flex items-center ">
                <input
                  name="access_id"
                  id="radio-1"
                  type="radio"
                  checked={accessIdSelected}
                  onChange={handleIdType}
                  className="w-4 h-4 rounded-full bg-primary-dark mr-4"
                />
                <label
                  for="radio-1"
                  className="text-heading font-semibold text-h5"
                >
                  Generate Access IDs
                </label>
              </div>
              <div className="flex items-center">
                <input
                  name="order_id"
                  type="radio"
                  id="radio-2"
                  checked={!accessIdSelected}
                  onChange={handleIdType}
                  className="w-4 h-4 rounded-full bg-primary-dark mr-4"
                />
                <label
                  for="radio-2"
                  className="text-heading font-semibold text-h5"
                >
                  Generate Order IDs
                </label>
              </div>
            </div>
            <div className="text-right">
              <BtnSmall
                onClick={() => {
                  setModals((prevState) => ({
                    ...prevState,
                    generateIds: false,
                  }));
                  setGenerateAccessIds(true);
                }}
              >
                Confirm
              </BtnSmall>
            </div>
            <button
              onClick={() =>
                setModals((prevState) => ({ ...prevState, generateIds: false }))
              }
              className="absolute top-8 right-8"
            >
              <svg
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.2176 12.8188C10.9475 13.0888 10.5813 13.2405 10.1994 13.2405C9.81747 13.2405 9.45122 13.0888 9.18117 12.8188L5.99997 9.18281L2.81877 12.8176C2.68545 12.9531 2.52663 13.0609 2.35145 13.1347C2.17628 13.2085 1.98823 13.2469 1.79814 13.2477C1.60805 13.2485 1.41969 13.2116 1.24392 13.1392C1.06815 13.0668 0.908454 12.9604 0.77404 12.8259C0.639626 12.6915 0.533155 12.5318 0.460769 12.3561C0.388382 12.1803 0.351512 11.9919 0.352286 11.8018C0.35306 11.6118 0.391462 11.4237 0.465278 11.2485C0.539094 11.0734 0.646862 10.9145 0.782367 10.7812L4.09197 7.00121L0.781167 3.21881C0.645662 3.0855 0.537894 2.92667 0.464078 2.7515C0.390263 2.57633 0.35186 2.38827 0.351086 2.19818C0.350312 2.00809 0.387182 1.81973 0.459569 1.64397C0.531955 1.4682 0.638426 1.3085 0.77284 1.17409C0.907255 1.03967 1.06695 0.933201 1.24272 0.860814C1.41849 0.788427 1.60685 0.751557 1.79694 0.752331C1.98703 0.753105 2.17508 0.791508 2.35025 0.865323C2.52543 0.939139 2.68425 1.04691 2.81757 1.18241L5.99997 4.81961L9.18117 1.18241C9.31448 1.04691 9.47331 0.939139 9.64848 0.865323C9.82365 0.791508 10.0117 0.753105 10.2018 0.752331C10.3919 0.751557 10.5802 0.788427 10.756 0.860814C10.9318 0.933201 11.0915 1.03967 11.2259 1.17409C11.3603 1.3085 11.4668 1.4682 11.5392 1.64397C11.6116 1.81973 11.6484 2.00809 11.6476 2.19818C11.6469 2.38827 11.6085 2.57633 11.5347 2.7515C11.4608 2.92667 11.3531 3.0855 11.2176 3.21881L7.90797 7.00121L11.2176 10.7812C11.3515 10.915 11.4577 11.0738 11.5301 11.2486C11.6026 11.4234 11.6399 11.6108 11.6399 11.8C11.6399 11.9893 11.6026 12.1766 11.5301 12.3514C11.4577 12.5263 11.3515 12.6851 11.2176 12.8188Z"
                  fill="black"
                  fillOpacity="0.53"
                />
              </svg>
            </button>
          </div>
        </DialogBox>
      )}
      </>)}
    </Page>
  );
}
