import { useState } from "react";
import { createPortal } from "react-dom";
import Input from "../../components/form/input";
import { BtnBlock, BtnBlockDisabled, DialogBox, PopUp } from "../../components";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as ROUTES from "../../constant/routes";
import login1 from "../../assets/images/login/login1.png";
import Loader from "../../components/loader";
import { handleForgotPassword } from "../../assets/Js/script";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import logo from "../../assets/images/logo.png";


export default function Signup() {
  const [email, setEmail] = useState("");
  const [resend, setResend] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const navigate = useNavigate();

  const [slideShowButtons, setSlideShowButtons] = useState([
    { id: 0, active: true },
    { id: 1, active: false },
    { id: 2, active: false },
  ]);

  const notify = () =>
    toast.warn("No user found with this email", {
      position: "top-center",
    });

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoaderActive(true);
    const { response } = await handleForgotPassword(email);
    if ((await response.status) !== "fail") {
      setIsLoaderActive(false);
      setShowPopup(true);
    } else {
      setIsLoaderActive(false);
      notify();
    }
  }
  function handleClose(e) {
    setShowPopup(false);
    setResend(true);
  }

  return (
    <div className="min-w-screen flex min-h-screen box-border ">
      <div className="mt-12 lg:mt-0 w-full lg:w-3/5 flex justify-center lg:items-center border-r-0 lg:border-r-8  border-r-secondary-yellow border-solid ">
        <div className="fixed mt-10 ml-8  top-0 left-0 flex flex-row items-center justify-start">
          <img className="" src={logo} alt="plb's logo" />
          <h2 className="font-bold ml-4">ePrakashak</h2>
        </div>
        <form
          onSubmit={handleSubmit}
          className="flex px-2 flex-col w-[412px] h-[390px] lg:h-[375px] "
        >
          <h1 className="mb-5 text-[21px] lg:text-h1 text-tertiary-dark font-bold ">
            Register
          </h1>
          <p className="mb-10 text-secondary-light font-semibold text-p1 lg:text-[17px]  text-justify">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, leo
            sed turpis mattis risus aliquet tellus a e.
          </p>
          <label
            htmlFor="email"
            className="text-heading-false text-montserrat mb-[5px] font-semibold text-h5"
          >
            Email address
          </label>
          <input
            className="border-1 border-solid border-primary-dark-20 font-semibold text-h5 pl-2 h-16"
            type="text"
            id="email"
            placeholder="Enter Email"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            required
          />
          <button
            disabled={email === ""}
            className=" mt-12 w-full h-[54px] bg-primary-dark text-primary-white border-1 border-solid border-[#c9c4c4] disabled:bg-[#854879] font-semibold text-h4"
          >
            Submit
          </button>
          <p className="text-center mt-6 text-heading-false text-p1 font-semibold">
            Didn't recieve the link?
            <span onClick={handleSubmit} className="ml-1 text-tertiary-dark cursor-pointer">Resend</span>
          </p>
        </form>
      </div>
      <div className="hidden w-full lg:w-2/5 lg:h-screen justify-center items-center relative bg-primary-dark lg:flex">
        <div className="bg-primary-dark flex flex-col items-center w-11/12 ">
          <div className="w-full">
            <img
              className="h-11/12 px-3 lg:px-0 lg:h-full block"
              src={login1}
              alt="login slideshow 1"
            />
          </div>
          <p className="text-secondary-light mt-10 text-h4 font-semibold text-center">
            Lorem ipsum dolor sit amet,
            <br /> consectetur adi
          </p>

          <div className="w-24 h-4 mt-8 lg:mt-10 flex justify-evenly mx-auto items-center">
            {slideShowButtons.map((item) =>
              item.active ? (
                <button
                  key={item.id}
                  className="w-8 h-2 bg-primary-white rounded-l-xl rounded-r-xl"
                ></button>
              ) : (
                <button
                  key={item.id}
                  className="w-2 h-2 bg-primary-white rounded-full "
                ></button>
              )
            )}
          </div>
        </div>

        {/* relative elements */}
        <div className="absolute top-8 -left-12">
          <svg
            width="115"
            height="164"
            viewBox="0 0 115 164"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_1766_5585)">
              <path
                d="M71.6674 140.24C86.3853 124.86 124.585 92.5501 109.206 51.2368C93.8275 9.92345 2.64301 -36.1161 2.71204 54.3921C2.73783 88.394 -0.691774 137.369 9.64573 151.697C26.8222 175.508 62.48 149.839 71.6674 140.24Z"
                fill="#ECEDFF"
                fillOpacity="0.3"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1766_5585"
                x="0.186523"
                y="0.140991"
                width="114.603"
                height="163.396"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1"
                  result="effect1_foregroundBlur_1766_5585"
                />
              </filter>
            </defs>
          </svg>
        </div>

        <div className="absolute top-0 right-0">
          <svg
            width="77"
            height="148"
            viewBox="0 0 77 148"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_1766_5588)">
              <path
                d="M38.5882 9.9185C24.8818 26.4457 -11.2997 61.7583 7.60116 101.69C26.502 141.621 122.697 180.101 115.404 89.8871C112.665 55.9957 112.237 6.89761 100.605 -6.54347C81.2764 -28.8822 47.1443 -0.396586 38.5882 9.9185Z"
                fill="#ECEDFF"
                fillOpacity="0.3"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1766_5588"
                x="0.25"
                y="-16.8807"
                width="117.548"
                height="163.97"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1"
                  result="effect1_foregroundBlur_1766_5588"
                />
              </filter>
            </defs>
          </svg>
        </div>

        <div className="absolute bottom-8 left-0">
          <svg
            width="115"
            height="164"
            viewBox="0 0 115 164"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_1766_5585)">
              <path
                d="M71.6674 140.24C86.3853 124.86 124.585 92.5501 109.206 51.2368C93.8275 9.92345 2.64301 -36.1161 2.71204 54.3921C2.73783 88.394 -0.691774 137.369 9.64573 151.697C26.8222 175.508 62.48 149.839 71.6674 140.24Z"
                fill="#ECEDFF"
                fillOpacity="0.3"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1766_5585"
                x="0.186523"
                y="0.140991"
                width="114.603"
                height="163.396"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1"
                  result="effect1_foregroundBlur_1766_5585"
                />
              </filter>
            </defs>
          </svg>
        </div>

        <div className="absolute bottom-8 right-0">
          <svg
            width="50"
            height="165"
            viewBox="0 0 50 165"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_1766_5589)">
              <path
                d="M38.5882 26.9185C24.8818 43.4457 -11.2997 78.7583 7.60116 118.69C26.502 158.621 122.697 197.101 115.404 106.887C112.665 72.9957 112.237 23.8976 100.605 10.4565C81.2764 -11.8822 47.1443 16.6034 38.5882 26.9185Z"
                fill="#ECEDFF"
                fillOpacity="0.3"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1766_5589"
                x="0.25"
                y="0.119263"
                width="117.548"
                height="163.97"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1"
                  result="effect1_foregroundBlur_1766_5589"
                />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
      {showPopup &&
        createPortal(
          <DialogBox show={showPopup}>
            <PopUp
              button="Proceed to login"
              title="Mail Sent!"
              main="Please check your inbox for a recovery mail."
              body="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget utvest-ibulum bibendum egestas. Enim mi arcu convallis enim purus interdum. Ut neque at adipiscing tortor facilisis sagittis libero. Ac bibendum vel blandit."
              onClose={handleClose}
            />
          </DialogBox>,
          document.body
        )}
        <Loader show={isLoaderActive} />
        <ToastContainer position="top-center" />
    </div>
  );
}
